import paper from "paper";
import pen from "./pen";
import move from "./move";
import WandTool from "./wand-ol";
function cropper(canvasId, options, ImgWidth, ImgHeight) {
  let me = this;
  //install to global
  destroy();
  if (!window.paper) {
    paper.setup(canvasId);
  }
  const defaultOption = {
    move: true,
    select: true,
    zoom: true,
    fullZoom: true,
    strokeColor: "#39f",
    selectedColor: null,
    fillColor: "rgba(0, 0, 0, 0.1)",
    onDrawEnd: null,
    onSelected: null,
  };

  me.options = Object.assign({}, defaultOption, options);


  // let canvas =
  //   typeof canvasId === "string" ? document.getElementById(canvasId) : canvasId;
  // if (!canvas) throw new Error("canvas does not exist");
  canvasId.width = 600;
  canvasId.height = 600;
  canvasId.oncontextmenu = function (e) {
    e.preventDefault();
  };
  let imageForWand;
  let Layer2;
  let tool = new paper.Tool();
  let wandTool = new WandTool(
      tool,
      () => {
        startAction();
      },
      me.options
  );
  let myMove = new move(tool, canvasId, me.options);
  startAction();
  let myPen = new pen(
      tool,
      () => {
        startAction();
      },
      me.options
  );

  let raster = new paper.Raster();
  raster.position = paper.view.center;
  function startPen(option) {
    stopAction();
    myPen.drawPath(Object.assign({}, me.options, option));
  }
  function startRectangle(option) {
    stopAction();
    myPen.drawRectangle(Object.assign({}, me.options, option));
  }
  function startCircle(option) {
    stopAction();
    myPen.drawCircle(Object.assign({}, me.options, option));
  }
  function startWand(option) {
    console.log(option);
    stopAction();
    let pathOndraw = myPen.initPath(Object.assign({}, options, option));
    let Info = {
      x: raster.bounds.x,
      y: raster.bounds.y,
      width: raster.bounds.width,
      height: raster.bounds.height,
    };
    if (Layer2) {
      console.log("layer Exist.");
    } else {
      Layer2 = new paper.Layer({
        children: [pathOndraw],
        strokeColor: "black",
        position: paper.view.center,
      });
    }

    paper.project.addLayer(Layer2);
    paper.project.layers[1].fitBounds(paper.view.bounds, false);
    paper.project.layers[1].setBounds(
        new paper.Rectangle(Info.x, Info.y, Info.width, Info.height)
    );
    wandTool.initCanvas(imageForWand, pathOndraw, Info);
    wandTool.startMagicWand(Object.assign({}, me.options, option));
  }
  /*
   * full move model
   * it will move image and drawing object when you selected image
   * it will move path if you selected path
   */
  function enableFullMove() {
    raster.move = false;
    raster.zoom = false;
    me.options.fullMove = true;
  }
  function disableFullMove() {
    raster.move = true;
    raster.zoom = true;
    me.options.fullMove = false;
  }
  function getPos() {
    myPen.clear();
    if (raster.source === "data:,") return;
    let output = [];
    for (let i = 0; i < paper.project.layers[0].children.length; i++) {
      let item = paper.project.layers[0].children[i];
      if (item instanceof paper.Path && item.closed) {
        output.push(item.getPos());
      }
    }
    // console.log("output", output);
    if (output.length == 0) {
      output = [];
      var cropWidth, cropHeight;
      let startPoint;
      if (ImgWidth > 600 || ImgHeight > 600) {
        cropWidth = raster.bounds.width;
        cropHeight = raster.bounds.height;
        startPoint = new paper.Point(raster.bounds.x, raster.bounds.y);
        myPen.drawisEmpty(
            Object.assign({}, me.options, defaultOption),
            cropWidth,
            cropHeight,
            startPoint
        );
      } else {
        myPen.drawisEmpty(
            Object.assign({}, me.options, defaultOption),
            ImgWidth - 1,
            ImgHeight - 1
        );
      }

      for (let i = 0; i < paper.project.layers[0].children.length; i++) {
        let item = paper.project.layers[0].children[i];
        if (item instanceof paper.Path && item.closed) {
          output.push(item.getPos());
        }
      }
    }
    // console.log("output", output);
    return output[output.length - 1];
  }
  function clear() {
    // if (raster) raster.position = paper.view.center;
    // paper.view.zoom = 1;
    for (let j = 0; j < paper.project.layers.length; j++) {
      let layer = paper.project.layers[j];
      for (let i = layer.children.length - 1; i > 0; i--) {
        let item = layer.children[i];
        if (item instanceof paper.Path) {
          let item = layer.children[i];
          item.remove();
        }
      }
    }
  }
  function destroy() {
    if (paper && paper.projects && paper.projects.length > 0) {
      // if (raster) raster.clear();
      // clear();
      paper.tool.remove();
      paper.project.clear();
      paper.projects = [];
      paper.view.remove();
    }
  }
  function crop(imgPos) {
    let canvas = document.createElement("CANVAS");
    canvas.width = imgPos.bounds.width;
    canvas.height = imgPos.bounds.height;
    let ctx = canvas.getContext("2d");
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(
        imgPos.points[0].x - imgPos.bounds.x,
        imgPos.points[0].y - imgPos.bounds.y
    );
    for (let i = 1; i < imgPos.points.length; i++) {
      ctx.lineTo(
          imgPos.points[i].x - imgPos.bounds.x,
          imgPos.points[i].y - imgPos.bounds.y
      );
    }
    ctx.clip();
    ctx.drawImage(
        raster.image,
        imgPos.bounds.x,
        imgPos.bounds.y,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
    );
    ctx.restore(); /// restore de
    return canvas.toDataURL();
  }
  function cropBounds(imgPos) {
    let canvas = document.createElement("CANVAS");
    canvas.width = imgPos.bounds.width;
    canvas.height = imgPos.bounds.height;
    let ctx = canvas.getContext("2d");
    ctx.save();
    ctx.drawImage(
        raster.image,
        imgPos.bounds.x,
        imgPos.bounds.y,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
    );
    ctx.restore();
    return canvas.toDataURL();
  }
  function setImage(source) {
    let image = new Image();
    image.src = source;
    image.onload = function () {
      imageForWand = image;
      raster.source = source;
      var imageSize = new paper.Size(image.naturalWidth, image.naturalHeight);
      if (imageSize.width > 600 || imageSize.height > 600) {
        var scale = Math.min(
            600 / imageSize.width,
            600 / imageSize.height,
            1
        );
        raster.scaling = scale;
        raster.position = paper.view.center;
        raster.origin = raster.bounds.center;
        paper.project.layers[0].fitBounds(paper.view.bounds, false);
      } else {
        // paper.view.viewSize = imageSize;
        canvasId.width = image.naturalWidth;
        canvasId.height = image.naturalHeight;
        raster.position = new paper.Point(
            image.naturalWidth / 2,
            image.naturalHeight / 2
        );
      }
      // console.log(
      //   "raster: ",
      //   raster.width,
      //   raster.height,
      //   raster.bounds,
      //   raster.scaling,
      //   "paper: ",
      //   paper.view.size.width,
      //   paper.view.size.height,
      //   "paper.view.bounds: ",
      //   paper.view.bounds,
      //   "image: "
      // );
    };
  }
  function zoomIn() {
    return  myMove.scaleOffset(raster,0.1);
  }
  function zoomOut() {
    return  myMove.scaleOffset(raster,-0.1);
  }

  function getImage() {
    return raster.source === "data:," ? undefined : raster.source;
  }
  function startAction() {
    myMove.start();
  }
  function stopAction() {
    myMove.stop();
    myPen.clear();
  }
  function draw(points, option) {
    let newPoints = [...points];
    let imgX = raster.bounds.x;
    let imgY = raster.bounds.y;
    for (let i = 0; i < newPoints.length; i++) {
      let newPoint = newPoints[i];
      newPoint.x = newPoint.x * raster.scaling.x + imgX;
      newPoint.y = newPoint.y * raster.scaling.y + imgY;
    }
    return myPen.draw(points, Object.assign({}, me.options, option));
  }

  paper.Path.prototype.getPos = function () {
    let x = raster.bounds.x;
    let y = raster.bounds.y;
    let realPos = [];
    let minX, maxX, minY, maxY;
    for (let j = 0; j < this.curves.length; j++) {
      let point = this.curves[j].points[0];
      let newX = (point.x - x) / raster.scaling.x;
      let newY = (point.y - y) / raster.scaling.x;
      realPos.push({ x: newX, y: newY });
      if (!minX || minX > newX) minX = newX;
      if (!maxX || maxX < newX) maxX = newX;
      if (!minY || minY > newY) minY = newY;
      if (!maxY || maxY < newY) maxY = newY;
    }
    let boundWidth = maxX - minX;
    let boundHeight = maxY - minY;
    return {
      bounds: { x: minX, y: minY, width: boundWidth, height: boundHeight },
      boundPos: [
        { x: minX, y: minY },
        { x: minX + boundWidth, y: minY },
        { x: minX + boundWidth, y: minY + boundHeight },
        { x: minX, y: minY + boundHeight },
      ],
      points: realPos,
    };
  };
  return {
    zoomOut,
    zoomIn,
    setImage,
    getImage,
    getPos,
    startPen,
    startRectangle,
    startCircle,
    startWand,
    clear,
    destroy,
    crop,
    cropBounds,
    options: me.options,
    objects: paper.project.layers[0].children,
    draw,
    enableFullMove,
    disableFullMove,
  };
}
window.Cropper = cropper;
export default cropper;
