<template>
  <div class="container-div">
    <button class="back-to-main-button" @click="backToDesign">
      <i class="bi bi-arrow-left"></i>Back to Main Page
    </button>
    <div v-if="!warningText">
      <div v-if="newFolderIsClicked" class="new-folder-container">
        <div class="location-text">Location:</div>
        <input type="text" id="new-folder-location-input" :value="middleName" :disabled="true" />
        <input type="text" id="new-folder-name-input" placeholder="New Folder Name" @input="handleNewFolderInput" />
        <button class="save-new-folder-button" @click="handleNewfolder" :style="{
          width: saveButtonDisplay === 'New Folder' ? '70px' : '0',
          margin: saveButtonDisplay === 'New Folder' ? '5px 5px 5px 0' : '0',
        }">
          Save <i class="bi bi-check-lg"></i>
        </button>
      </div>
      <div class="date-picker-div">
        <div class="container-title">Certificates</div>

        <button class="expand-button" @click="handleExpand(!isExpanded)" :style="{
          filter: newFolderIsClicked ? 'blur(2.5px)' : 'none',
          pointerEvents: newFolderIsClicked ? 'none' : 'auto',
        }">
          {{ isExpanded ? "Shrink" : "Expand" }}
          <i v-if="isExpanded" class="bi bi-arrows-angle-contract"></i>
          <i v-else class="bi bi-arrows-angle-expand"></i>
        </button>
        <button class="edit-button" :disabled="isDatePicked || isTimeRangeFiltersActive"
          @click="handleFolderEdit(!isFolderEdited)" :style="{
            color: isFolderEdited ? 'red' : 'black',
            filter: newFolderIsClicked ? 'blur(2.5px)' : 'none',
            pointerEvents: newFolderIsClicked ? 'none' : 'auto',
            opacity: isDatePicked || isTimeRangeFiltersActive ? '0.3' : '1',
          }">
          {{ isFolderEdited ? "Cancel" : "Folder Edit"
          }}<i class="bi bi-pen-fill"></i>
        </button>
        <button class="new-folder-button" :disabled="isDatePicked || isTimeRangeFiltersActive"
          @click="handleNewFolderButton(!newFolderIsClicked)" :style="{
            color: newFolderIsClicked ? 'red' : 'black',
            opacity: isDatePicked || isTimeRangeFiltersActive ? '0.3' : '1',
          }">
          {{ newFolderIsClicked ? "Cancel" : "New Folder" }}
          <i v-if="newFolderIsClicked" class="bi bi-x-lg"></i>
          <i v-else class="bi bi-plus-lg"></i>
        </button>
        <div class="time-filter-container" :style="{
          filter: newFolderIsClicked ? 'blur(2.5px)' : 'none',
          pointerEvents: newFolderIsClicked ? 'none' : 'auto',
          opacity: isDatePicked ? '0.3' : '1',
        }">
          <button class="filter-button" :disabled="isDatePicked" @click="
            handleFilter('Today');
          getFilteredCertificates();
          " :style="{
  backgroundColor: filterIsSelected === 'Today' ? 'black' : 'white',
  color: filterIsSelected === 'Today' ? 'white' : 'black',
}">
            Today
          </button>
          <button class="filter-button" :disabled="isDatePicked" @click="
            handleFilter('Last 7 Days');
          getFilteredCertificates();
          " :style="{
  backgroundColor:
    filterIsSelected === 'Last 7 Days' ? 'black' : 'white',
  color: filterIsSelected === 'Last 7 Days' ? 'white' : 'black',
}">
            Last 7 Days
          </button>
          <button class="filter-button" :disabled="isDatePicked" @click="
            handleFilter('Last 30 Days');
          getFilteredCertificates();
          " :style="{
  backgroundColor:
    filterIsSelected === 'Last 30 Days' ? 'black' : 'white',
  color: filterIsSelected === 'Last 30 Days' ? 'white' : 'black',
}">
            Last 30 Days
          </button>
        </div>

        <Datepicker id="datePicker" v-model="picked" :disabled-dates="getDisabledDates" typeable
          :disabled="searchButtonStatus || isTimeRangeFiltersActive" :style="{
            filter: newFolderIsClicked ? 'blur(2.5px)' : 'none',
            pointerEvents: newFolderIsClicked ? 'none' : 'auto',
            backgroundColor: isDatePicked ? 'grey' : 'white',
            color: isDatePicked ? 'white' : 'black',
            opacity: isTimeRangeFiltersActive ? '0.3' : '1',
          }" />
        <div style="display: flex; flex-direction: row; justify-content: center">
          <button class="search-button" @click="searchCertificate"
            :disabled="searchButtonStatus || isTimeRangeFiltersActive" :style="{
              filter: newFolderIsClicked ? 'blur(2.5px)' : 'none',
              pointerEvents: newFolderIsClicked ? 'none' : 'auto',
              opacity: isTimeRangeFiltersActive ? '0.3' : '1',
            }">
            <i class="bi bi-search"></i>
          </button>
          <button v-if="!removeFilterButtonStatus" style="
              background-color: transparent;
              border: none;
              font-size: 12px;
              color: red;
            " @click="removeFilter">
            Clear
          </button>
        </div>
      </div>
      <div class="certificate-main-container" :style="{
        transform:
          isExpanded && calculateFloor >= 4
            ? 'translateY(' + 50 * calculateFloor + 'px)'
            : 'translateY(0)',
        filter: newFolderIsClicked ? 'blur(10px)' : 'none',
        pointerEvents: newFolderIsClicked ? 'none' : 'auto',
      }">
        <div class="path">
          {{ buildPath }}
        </div>
        <div class="action-button-container">
          <button v-if="isCertificateClicked || (isFolderClicked && isFolderEdited)" class="certificate-action-button"
            :style="{ color: 'red', animationDelay: '0.1s' }" @mouseover="deleteButtonHover = true"
            @click="deleteCertificate()">
            <i class="bi bi-trash delete-icon"></i>
          </button>
          <button v-if="isCertificateClicked" :style="{ animationDelay: '0.2s' }" class="certificate-action-button"
            @click="downloadPDF(isCertificateClicked.id)">
            <i class="bi bi-box-arrow-down"></i>
          </button>
          <button class="certificate-action-button" v-if="isCertificateClicked || (isFolderClicked && isFolderEdited)"
            :style="{ animationDelay: '0.3s' }" @click="handleEdit">
            <i class="bi bi-pen-fill"></i>
          </button>
        </div>

        <button v-if="isCertificateClicked || (isFolderClicked && isFolderEdited)" class="certificate-close-button"
          @click="handleClick('close', null)">
          <i class="bi bi-x-lg"></i>
        </button>
        <div class="middle-title-certificate" ref="middleTitle" :style="{
          left: isFolderEdited
            ? isFolderClicked
              ? '75%'
              : '50%'
            : isCertificateClicked
              ? '75%'
              : '50%',
        }">
          {{ middleName }}
        </div>
        <!--CERTIFICATE NODES START-->
        <VueDraggableNext v-model="certificateArray" :move="handleCertMove" @drop="handleDropTargetCert"
          @start="handleExpand(true), handleHover(null)" @end="handleExpand(false)">
          <div class="certificate-node" v-for="(certificate, idx) in certificateArray" :key="certificate.certificate_id"
            @mouseenter="handleHover(certificate)" @mouseleave="handleHover(null)" @click="
              handleClick('certificate', certificate),
              handleDisplay(true),
              displayPDF(isCertificateClicked.id)
              " :style="{
    transform: isFolderLoading
      ? 'rotate(0) translate(-50%,-50%) scale(1.5)'
      : isCertificateClicked === certificate
        ? 'rotate(0) scale(3) translateY(-50%)'
        : isExpanded
          ? 'rotate(' +
          idx * (360 / (certificateArray.length + folderArray.length)) +
          'deg) translate(' +
          (80 + (idx % calculateFloor) * 70) +
          'px, ' +
          (80 + (idx % calculateFloor) * 70) +
          'px) scale(0.7)'
          : 'rotate(' +
          idx * (360 / (certificateArray.length + folderArray.length)) +
          'deg) translate(70px, 70px)',
    left: isFolderEdited
      ? isFolderClicked
        ? '75%'
        : '50%'
      : isCertificateClicked
        ? isCertificateClicked === certificate
          ? '20%'
          : '75%'
        : '50%',
    transformOrigin: isFolderLoading ? 'center center' : 'top left',
    overflow: isCertificateClicked === certificate ? 'hidden' : '',
    cursor: isCertificateClicked === certificate ? 'auto' : 'pointer',
    borderRadius: isFolderLoading ? '100%' : '0',
    backgroundColor: selectBackgroundColor[idx],
    zIndex: isIndexed ? 5 - (idx % calculateFloor) : '0',
    filter: isFolderEdited ? 'blur(10px)' : 'none',
    pointerEvents:
      isFolderEdited || newFolderIsClicked ? 'none' : 'auto',
  }">
            <button v-if="editIsClicked === certificate" class="save-name-button" @click="saveCertificateName" :style="{
              width:
                saveButtonDisplay === 'Certificate Rename' ? '70px' : '0',
            }">
              Save <i class="bi bi-check-lg"></i>
            </button>
            <div class="inside-node" :style="{
              transform:
                isCertificateClicked === certificate
                  ? displayIsClicked
                    ? 'rotate(0deg) '
                    : 'rotate(0deg) translateY(50%)'
                  : 'rotate(' +
                  -idx *
                  (360 / (certificateArray.length + folderArray.length)) +
                  'deg)',
              bottom:
                isCertificateClicked === certificate
                  ? displayIsClicked
                    ? '15px'
                    : '50%'
                  : isHovered === certificate
                    ? '-60px'
                    : '15px',
              right:
                isCertificateClicked === certificate
                  ? 'calc(100% - 90px)'
                  : isHovered === certificate
                    ? '-60px'
                    : '15px',
              fontSize: isCertificateClicked === certificate ? '8px' : '12px',
              width: isCertificateClicked === certificate ? '80px' : '70px',
              height: isCertificateClicked === certificate ? 'auto' : '70px',
            }">
              <div class="update-div" v-if="editIsClicked === certificate">
                <textarea class="name-input" :ref="'certificate_' + certificate.id" :value="certificate.certificate_name"
                  @input="handleRename('certificate')" rows="1" cols="10" :style="{
                    width: calculateRenameInputWidth(
                      'certificate',
                      certificate
                    ),
                  }"></textarea>
              </div>
              <div class="node-name" v-else :style="{
                justifyContent:
                  isCertificateClicked === certificate
                    ? displayIsClicked
                      ? 'left'
                      : 'center'
                    : 'center',
              }">
                {{ certificate.certificate_name }}
                <span style="color: red; font-size: 8px" v-if="certificate.node_name">{{ "(" + certificate.node_name + ")"
                }}</span>
                <div :style="{ fontSize: '4px' }" v-if="isCertificateClicked === certificate &&
                  certificate.downloaded_at
                  ">
                  Downloaded on
                  {{
                    certificate.downloaded_at.substring(
                      0,
                      certificate.downloaded_at.length - 13
                    )
                  }}
                </div>
              </div>
            </div>
            <iframe v-if="(isCertificateClicked === certificate && displayIsClicked) && pdfStatus"
              :src="'data:application/pdf;base64,' + pdfData" type="application/pdf" class="pdf-container"></iframe>
          </div>

          <!--CERTIFICATE NODES END-->
          <!--CHILDREN NODES START-->
          <VueDraggableNext v-model="folderArray" :move="handleFolderMove" @drop="handleDropTargetFolder($event)"
            @choose="chooseElem" @start="handleExpand(true), handleHover(null)" @end="handleExpand(false)">
            <div :class="['back-button-container']" v-if="backHistoryStack.length && !isCertificateClicked"
              @click="handleBack()" :style="{
                pointerEvents: isFolderLoading ? 'none' : 'auto',
                opacity: isFolderLoading ? '0.5' : '1',
              }">
              <button class="back-button">
                <i :style="{ color: 'white' }" class="bi bi-arrow-left"></i>
              </button>
              <div>{{ prevName }}</div>
            </div>
            <div class="folder-node" v-for="(folder, idx) in folderArray" :key="folder.id"
              @mouseenter="handleHover(folder)" @mouseleave="handleHover(null)" @click="handleClick('folder', folder)"
              :style="{
                transform: isFolderEdited
                  ? isFolderClicked === folder
                    ? 'rotate(0) scale(3) translateY(-50%)'
                    : 'rotate(' +
                    (idx + certificateArray.length) *
                    (360 / (certificateArray.length + folderArray.length)) +
                    'deg) translate(70px, 70px) '
                  : isFolderLoading
                    ? 'rotate(0) translate(-50%,-50%) scale(1.5)'
                    : isExpanded
                      ? 'rotate(' +
                      (idx + certificateArray.length) *
                      (360 / (certificateArray.length + folderArray.length)) +
                      'deg) translate( ' +
                      (80 +
                        ((idx + certificateArray.length) % calculateFloor) * 70) +
                      'px, ' +
                      (80 +
                        ((idx + certificateArray.length) % calculateFloor) * 70) +
                      'px) scale(0.7)'
                      : 'rotate(' +
                      (idx + certificateArray.length) *
                      (360 / (certificateArray.length + folderArray.length)) +
                      'deg) translate(70px, 70px) ',
                transformOrigin: isFolderLoading ? 'center center' : 'top left',
                boxShadow: isFolderLoading ? 'none' : '0 0 5px 1px #ced4da',
                backgroundColor: 'black',
                color:
                  isHovered === folder
                    ? isFolderClicked === folder
                      ? '15px'
                      : 'black'
                    : 'white',
                left: isFolderEdited
                  ? isFolderClicked
                    ? isFolderClicked === folder
                      ? '20%'
                      : '75%'
                    : '50%'
                  : isCertificateClicked
                    ? '75%'
                    : '50%',

                zIndex: isIndexed
                  ? '10'
                  : isFolderClicked === folder
                    ? '2'
                    : '0',
                borderRadius: isFolderLoading
                  ? isFolderEdited
                    ? '0'
                    : '100%'
                  : '0',
              }">
              <div class="inside-node" :style="{
                transform: isFolderEdited
                  ? isFolderClicked === folder
                    ? 'rotate(0)'
                    : 'rotate(' +
                    (-idx - certificateArray.length) *
                    (360 /
                      (certificateArray.length + folderArray.length)) +
                    'deg)'
                  : isFolderLoading
                    ? 'rotate(0)'
                    : 'rotate(' +
                    (-idx - certificateArray.length) *
                    (360 / (certificateArray.length + folderArray.length)) +
                    'deg)',

                bottom: !isFolderLoading
                  ? isFolderClicked === folder
                    ? '15px'
                    : isHovered === folder
                      ? '-60px'
                      : '15px'
                  : '15px',
                right: !isFolderLoading
                  ? isFolderClicked === folder
                    ? '15px'
                    : isHovered === folder
                      ? '-60px'
                      : '15px'
                  : '15px',
                textAlign:
                  isCertificateClicked === certificate ? 'left' : 'center',
              }">
                <button v-if="editIsClicked === folder" class="save-name-button" @click="saveFolderName" :style="{
                  width: saveButtonDisplay === 'Folder Rename' ? '70px' : '0',
                }">
                  Save <i class="bi bi-check-lg"></i>
                </button>
                <textarea v-if="editIsClicked === folder" class="name-input" :ref="'folder_' + folder.id"
                  :value="folder.name" @input="handleRename('folder')" rows="1" cols="10" wrap="soft" :style="{
                    width: calculateRenameInputWidth('folder', folder),
                    border: '0.5px dashed white',
                    color: 'white',
                  }"></textarea>
                <div class="node-name" v-else>
                  {{ folder.name }}
                  <!-- <span v-if="folder.certificates.length === 0" style="font-size:8px; color: yellow;">(Empty)</span> -->
                </div>
              </div>
            </div>
          </VueDraggableNext>
        </VueDraggableNext>
        <!--CHILDREN NODES END-->
        <toast-view ref="toast"></toast-view>
      </div>
    </div>
    <div class="warning-div" v-if="warningText">
      <span class="warning-text">You don't have any certificates yet. It will be created automatically
        when you download any pattern.</span>
      <span class="warning-text">Please visit here again <b>after downloading the pattern.</b></span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useUserCertificateStore } from "@/store/modules/userCertificate";
import { useCertificateNodeStore } from "@/store/modules/certificateNode";
import Datepicker from "vue3-datepicker";
import ToastView from "@/components/admin/ToastView.vue";
import ColorPalettes from "@/views/Design/colors.json";
import { VueDraggableNext } from "vue-draggable-next";

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export default {
  components: {
    Datepicker,
    ToastView,
    VueDraggableNext,
  },
  data() {
    return {
      colorPalettes: ColorPalettes,
      certificatesData: null,
      folderArray: [],
      certificate: {},
      certificateArray: [],
      backHistoryStack: [],
      isFolderClicked: null,
      isFolderLoading: false,
      isFolderEdited: false,
      isCertificateClicked: null,
      middleName: "",
      prevName: "",
      randomPalette: null,
      isExpanded: false,
      increaseFloorCount: 50,
      isIndexed: false,
      editIsClicked: null,
      pdfData: null,
      newNodeName: "",
      displayIsClicked: false,
      newFolderIsClicked: false,
      newFolderName: "",
      filterIsSelected: "",
      saveButtonDisplay: "",
      currentParentId: null,
      availableDates: [],
      disabledDates: {},
      selectedCertificateDate: null,
      deleteButtonHover: false,
      picked: null,
      searchButtonStatus: false,
      loadMoreButtonStatus: false,
      spinnerStatus: false,
      warningText: false,
      isHovered: null,
      isClicked: null,
      isDragging: false,
      selectedSubFolder: 0,
      draggedElement: "",
      draggedObjID: null,
      updateButtonStatus: false,
      todayCertificates: [],
      certificateHolder: [],
      folderHolder: [],
      filterStatus: false,
      middleNameHolder: "",
      removeFilterButtonStatus: true,
      isDatePicked: false,
      pickedDateString: "",
      isTimeRangeFiltersActive: false,
      selectedFolderName: "",
      pdfStatus: false,
    };
  },
  computed: {
    getDisabledDates() {
      let dayList = this.getDaysArray(
        new Date(2023, 0, 1),
        new Date(2030, 11, 31)
      );
      let difference = dayList.filter((x) => !this.availableDates.includes(x));

      for (let i = 0; i < difference.length; i++) {
        difference[i] = new Date(difference[i]);
      }
      return {
        dates: difference,
      };
    },
    selectBackgroundColor() {
      const colorArray = [];
      let backgroundColor;
      for (let i = 0; i < this.certificateArray.length; i++) {
        const maxColor =
          this.colorPalettes.palettes[this.randomPalette].colors.length;
        const randomColor = Math.floor(Math.random() * maxColor);
        backgroundColor =
          this.colorPalettes.palettes[this.randomPalette].colors[randomColor];
        colorArray.push(backgroundColor);
      }
      return colorArray;
    },
    calculateFloor() {
      const floor = Math.floor(
        (this.certificateArray.length + this.folderArray.length) /
        this.increaseFloorCount +
        2
      );
      return floor;
    },
    buildPath() {
      let path = "";
      this.backHistoryStack.forEach((folder) => {
        path += folder.name + " > ";
      });
      path += this.middleName;
      if (this.isCertificateClicked) {
        path += " > " + this.isCertificateClicked.certificate_name;
      }
      return path;
    },
  },
  watch: {
    picked() {
      this.searchButtonStatus = false;
    },
    warningText(val) {
      if (val) {
        this.loadMoreButtonStatus = true;
        this.searchButtonStatus = true;
      }
    },
    newNodeName() {
      this.updateButtonStatus = true;
    },
    filterStatus(val) {
      if (val === true) {
        this.middleNameHolder = this.middleName;
        this.middleName = this.filterIsSelected;
      } else {
        this.middleName = this.middleNameHolder;
      }
    },
  },
  methods: {
    ...mapActions(useUserCertificateStore, [
      "getAvailableDatesRequest",
      "getCertificateByIdRequest",
      "markAsDownloadedRequest",
      "deleteCertificateRequest",
      "renameCertificateRequest",
      "insertNewFolderRequest",
      "updateFolderNameRequest",
      "getTodayCertificatesRequest",
      "getLast7DaysCertificatesRequest",
      "getLast30DaysCertificatesRequest",
      "getPickedDayCertificatesRequest",
    ]),
    ...mapActions(useCertificateNodeStore, [
      "getTreeDataRequest",
      "moveNodeItemRequest",
      "moveNodeRequest",
      "updateNodeNameRequest",
      "deleteNodeRequest",
    ]),
    getDaysArray(start, end) {
      for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt).getTime());
      }
      return arr;
    },
    async getAvailableDates() {
      try {
        this.spinnerStatus = true;
        await this.getAvailableDatesRequest().then((response) => {
          const availableDateArray = response.data.data.available_date_list;
          if (availableDateArray.length !== 0) {
            availableDateArray.map((date) => {
              this.availableDates.push(
                new Date(date.date + " 00:00:00").getTime()
              );
            });
            if (this.availableDates.length !== 0) {
              this.picked = new Date(this.availableDates[0]);
            }
          } else {
            this.spinnerStatus = false;
            this.warningText = true;
            this.loadMoreButtonStatus = true;
            this.searchButtonStatus = true;
          }
        });
      } catch (error) {
        this.spinnerStatus = false;
        this.loadMoreButtonStatus = true;
        this.searchButtonStatus = true;
        this.$refs.toast.triggerToast(
          error.response.data.errors[0],
          "bottom-right",
          "error"
        );
      }
    },
    async downloadPDF(certId) {
      await this.getCertificateByIdRequest(certId).then((response) => {
        let certData = response.data.data.certificate.certificate_data;
        console.log(certData);
        this.markAsDownloadedRequest(certId, certData).then((response) => {
          if (response.data.success) {
            const byteCharacters = atob(certData);
            const byteArrays = [];
            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: "application/pdf" });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `certificate-${certId}.pdf`;
            link.click();
          }
        });
      });
    },
    async displayPDF(certificate_id) {
      this.pdfStatus = false;
      await this.getCertificateByIdRequest(certificate_id).then((response) => {
        this.pdfData = response.data.data.certificate.certificate_data;
        this.pdfStatus = true;
      });
    },
    async deleteCertificate() {
      if (this.isFolderEdited === false) {
        let certId = this.isCertificateClicked.id;
        let certName = this.isCertificateClicked.certificate_name;
        this.searchButtonStatus = true;
        let selectedItem = this.certificateArray.filter(
          (cert) => cert.id === certId
        )[0];
        this.selectedCertificateDate = new Date(
          new Date(selectedItem.created_at).toISOString().split("T")[0] +
          " 00:00:00"
        ).getTime();
        try {
          await this.deleteCertificateRequest(certId, certName).then(
            (response) => {
              this.certificateArray = this.certificateArray.filter(
                (cert) => cert.id !== certId
              );
              if (this.certificateArray.length === 0) {
                this.availableDates = this.availableDates.splice(
                  this.selectedCertificateDate,
                  1
                );
                this.picked = null;
                this.getAvailableDates();
              }
              this.$refs.toast.triggerToast(
                response.data.message,
                "bottom-right",
                "success"
              );
              this.searchButtonStatus = false;
              this.handleClick("close", null);
              this.isFolderClicked === null;
            }
          );
        } catch (error) {
          this.spinnerStatus = false;
          this.searchButtonStatus = false;
          this.$refs.toast.triggerToast(
            error.response.data.errors[0],
            "bottom-right",
            "error"
          );
        }
      } else {
        try {
          await this.deleteNodeRequest(this.isFolderClicked.id).then(() => {
            this.folderArray = this.folderArray.filter(
              (folder) => folder.id !== this.isFolderClicked.id
            );
            this.handleClick("close", null);
            this.isFolderEdited = false;
          });
        } catch (error) {
          this.spinnerStatus = false;
          this.searchButtonStatus = false;
          this.$refs.toast.triggerToast(
            error.response.data.errors[0],
            "bottom-right",
            "error"
          );
        }
      }
    },
    handleClick(type, item) {
      if (type === "certificate") {
        this.isCertificateClicked = item;
        if (this.editIsClicked) {
          this.editIsClicked = item;
        }
      }
      if (type === "folder") {
        if (!this.isFolderEdited) {
          this.isFolderLoading = true;
          const middleTitle = this.$refs.middleTitle;
          middleTitle.style.zIndex = 1;
          if (!this.isFolderClicked) {
            this.prevName = this.certificatesData.name;
            this.backHistoryStack.push(this.certificatesData);
          } else {
            this.prevName = this.isFolderClicked.name;
            this.backHistoryStack.push(this.isFolderClicked);
          }
          this.isFolderClicked = item;
          this.currentParentId = this.isFolderClicked.id;
          sleep(750).then(() => {
            middleTitle.style.zIndex = 3;
            middleTitle.style.width = "160px";
            middleTitle.style.height = "160px";
            middleTitle.style.lineHeight = "160px";
            this.middleName = this.isFolderClicked.name;
          });
          sleep(1000).then(() => {
            this.folderArray = this.isFolderClicked.children.map(
              (folder) => folder
            );
            this.certificateArray = this.isFolderClicked.certificates.map(
              (certificate) => certificate
            );
            middleTitle.style.width = "150px";
            middleTitle.style.height = "150px";
            middleTitle.style.lineHeight = "150px";
            this.isFolderLoading = false;
          });
        } else {
          this.isFolderClicked = item;
        }
      }
      if (type === "close") {
        this.isCertificateClicked = item;
        this.editIsClicked = item;
        this.isFolderClicked = item;
        this.handleDisplay(item);
      }
    },
    handleBack() {
      const middleTitle = this.$refs.middleTitle;
      this.isFolderClicked =
        this.backHistoryStack[this.backHistoryStack.length - 1];
      this.currentParentId = this.isFolderClicked.id;
      this.folderArray = this.isFolderClicked.children.map((folder) => folder);
      this.certificateArray = this.isFolderClicked.certificates.map(
        (certificate) => certificate
      );
      this.middleName = this.isFolderClicked.name;
      this.backHistoryStack.pop();
      if (this.backHistoryStack.length) {
        this.prevName =
          this.backHistoryStack[this.backHistoryStack.length - 1].name;
      }
      middleTitle.style.zIndex = 3;
      middleTitle.style.width = "160px";
      middleTitle.style.height = "160px";
      middleTitle.style.lineHeight = "160px";
      sleep(250).then(() => {
        middleTitle.style.width = "150px";
        middleTitle.style.height = "150px";
        middleTitle.style.lineHeight = "150px";
      });
      this.isCertificateClicked = null;

      this.isHovered = null;
    },
    handleExpand(expand) {
      this.isExpanded = expand;
      if (expand) {
        sleep(500).then(() => {
          this.isIndexed = expand;
        });
      } else {
        this.isIndexed = expand;
      }
    },
    handleEdit() {
      if (this.isFolderEdited) {
        this.editIsClicked = this.isFolderClicked;
      } else {
        this.editIsClicked = this.isCertificateClicked;
      }
    },
    handleHover(hover) {
      this.isHovered = hover;
    },
    handleRename(type) {
      if (type === "certificate") {
        const elementName = "certificate_" + this.isCertificateClicked.id;
        const renameElement = this.$refs[elementName][0];
        this.newNodeName = renameElement.value;
        if (this.newNodeName) {
          this.saveButtonDisplay = "Certificate Rename";
        } else {
          this.saveButtonDisplay = "";
        }
      }
      if (type === "folder") {
        const elementName = "folder_" + this.isFolderClicked.id;
        const renameElement = this.$refs[elementName][0];
        this.newNodeName = renameElement.value;
        if (this.newNodeName) {
          this.saveButtonDisplay = "Folder Rename";
        } else {
          this.saveButtonDisplay = "";
        }
      }
    },
    handleDisplay(display) {
      this.displayIsClicked = display;
    },
    handleFolderEdit(edit) {
      this.isFolderClicked = null;
      this.isFolderEdited = edit;
    },
    handleNewFolderButton(click) {
      this.newFolderIsClicked = click;
    },
    handleNewFolderInput(newName) {
      this.newFolderName = newName.target.value;
      if (this.newFolderName) {
        this.saveButtonDisplay = "New Folder";
      } else {
        this.saveButtonDisplay = "";
      }
    },
    handleFilter(time) {
      this.isTimeRangeFiltersActive = !this.isTimeRangeFiltersActive;
      this.filterIsSelected = time;
    },
    async handleNewfolder() {
      await this.insertNewFolderRequest({
        node_name: this.newFolderName,
        parent_node_id: this.currentParentId,
      }).then(() => {
        this.newFolderIsClicked = false;
        this.getData();
        this.saveButtonDisplay = "";
        this.newFolderName = "";
      });
    },
    async saveFolderName() {
      await this.updateFolderNameRequest({
        node_id: this.isFolderClicked.id,
        new_node_name: this.newNodeName,
      }).then(() => {
        this.newNodeName = "";
        this.editIsClicked = false;
        this.saveButtonDisplay = "";
        this.isFolderClicked = null;
        this.getData();
      });
    },
    saveCertificateName() {
      let clickedCert = this.certificateArray.filter(
        (cert) => cert.id === this.isCertificateClicked.id
      )[0];
      if (clickedCert.certificate_name !== this.newNodeName) {
        this.renameCertificateRequest(
          this.isCertificateClicked.id,
          this.newNodeName
        )
          .then((response) => {
            if (response.data.success) {
              clickedCert.certificate_name = this.newNodeName;
              this.isCertificateClicked = null;
              this.editIsClicked = null;
              this.isFolderClicked = null;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleCertMove(event) {
      this.draggedObjID = event.draggedContext.element.id;
      if (event.dragged.className.startsWith("certificate")) {
        this.draggedElement = "cert";
      }
    },
    handleFolderMove(event) {
      this.draggedObjID = event.dragged.__vnode.key;
      if (event.dragged.className.startsWith("folder")) {
        this.draggedElement = "folder";
      }
    },
    handleDropTargetCert(event) {
      if (this.draggedElement === "cert") {
        this.moveNodeItem(event);
      }
    },
    handleDropTargetFolder(event) {
      if (this.draggedElement === "folder") {
        this.moveNode(event);
      } else if (this.draggedElement === "") {
        let myobj = this.folderArray.find(
          (folder) => folder.name === this.selectedFolderName
        );
        let removedFolderIndex = this.folderArray.findIndex(
          (folder) => folder.name === myobj.name
        );
        this.folderArray.splice(removedFolderIndex, 1);
        let nameArr = [];
        this.backHistoryStack[0].children.forEach((obj) => {
          nameArr.push(obj.name);
        });
        if (!nameArr.includes(myobj.name)) {
          this.backHistoryStack[0].children.push(myobj);
          const payload = {
            moved_node_id: myobj.id,
            target_node_id: this.backHistoryStack[0].id,
          };
          this.moveNodeRequest(payload).then(() => {
            this.draggedObjID = "";
            this.draggedElement = "";
            this.selectedFolderName = "";
          });
        }
      }
    },
    chooseElem(event) {
      this.selectedFolderName = event.item.innerText;
    },
    moveNodeItem(event) {
      let targetNode = this.folderArray.filter(
        (folder) => folder.name === event.target.innerText
      )[0];
      if (this.prevName === event.target.innerText) {
        targetNode = this.backHistoryStack[this.backHistoryStack.length - 1];
      }
      if (this.draggedObjID && targetNode) {
        let draggedCert = this.certificateArray.find(
          (cert) => cert.id === this.draggedObjID
        );
        if (this.isFolderClicked) {
          this.isFolderClicked.certificates =
            this.isFolderClicked.certificates.filter(
              (certificate) => certificate.id !== this.draggedObjID
            );
        } else {
          this.certificatesData.certificates =
            this.certificatesData.certificates.filter(
              (certificate) => certificate.id !== this.draggedObjID
            );
        }
        this.certificateArray = this.certificateArray.filter(
          (certificate) => certificate.id !== this.draggedObjID
        );
        let certIdArr = [];
        targetNode.certificates.forEach((certObj) => {
          certIdArr.push(certObj.id);
        });
        if (!certIdArr.includes(this.draggedObjID)) {
          targetNode.certificates.push(draggedCert);
        }
        const payload = {
          moved_item_id: this.draggedObjID,
          target_node_id: targetNode.id,
        };
        this.moveNodeItemRequest(payload).then(() => {
          this.draggedObjID = "";
          this.draggedElement = "";
        });
      }
    },
    moveNode(event) {
      let targetNode = this.folderArray.filter(
        (folder) => folder.name === event.target.innerText
      )[0];
      if (this.prevName === event.target.innerText) {
        targetNode = this.backHistoryStack[this.backHistoryStack.length - 1];
      }
      let folderIdArr = [];
      if (targetNode.children.length !== 0) {
        targetNode.children.forEach((folderObj) => {
          folderIdArr.push(folderObj.id);
        });
      }
      let draggedFolder = this.folderArray.find(
        (folder) => folder.id === this.draggedObjID
      );
      if (!folderIdArr.includes(this.draggedObjID)) {
        targetNode.children.push(draggedFolder);
      }
      this.folderArray = this.folderArray.filter(
        (folder) => folder.id !== this.draggedObjID
      );
      if (this.isFolderClicked) {
        this.isFolderClicked.children = this.isFolderClicked.children.filter(
          (folder) => folder.id !== this.draggedObjID
        );
      } else {
        this.certificatesData.children = this.certificatesData.children.filter(
          (folder) => folder.id !== this.draggedObjID
        );
      }
      if (this.draggedObjID) {
        if (this.draggedObjID !== targetNode.id) {
          const payload = {
            moved_node_id: this.draggedObjID,
            target_node_id: targetNode.id,
          };
          this.moveNodeRequest(payload).then(() => {
            this.draggedObjID = "";
            this.draggedElement = "";
          });
        }
      }
    },
    calculateRenameInputWidth(type, item) {
      if (type === "folder") {
        const elementName = "folder_" + item.id;
        this.$nextTick(() => {
          const renameElement = this.$refs[elementName][0];
          renameElement.rows = Math.floor(
            renameElement.value.length / renameElement.cols + 1
          );
        });
      }
      if (type === "certificate") {
        const elementName = "certificate_" + item.id;
        this.$nextTick(() => {
          const renameElement = this.$refs[elementName][0];
          renameElement.rows = Math.floor(
            renameElement.value.length / renameElement.cols + 1
          );
        });
      }
    },
    backToDesign() {
      this.$router.push("/");
    },
    async getFilteredCertificates() {
      this.spinnerStatus = true;
      if (this.filterStatus === false) {
        if (this.filterIsSelected === "Today") {
          try {
            await this.getTodayCertificatesRequest().then((response) => {
              this.filterStatus = true;
              this.certificateHolder = this.certificateArray;
              this.folderHolder = this.folderArray;
              this.certificateArray = [];
              this.folderArray = [];
              this.certificateArray = response.data.data.certificate_list;
              this.spinnerStatus = false;
            });
          } catch (error) {
            this.spinnerStatus = false;
            this.filterIsSelected = "";
            this.isTimeRangeFiltersActive = false;
            this.$refs.toast.triggerToast(
              error.response.data.errors[0],
              "bottom-right",
              "error"
            );
          }
        } else if (this.filterIsSelected === "Last 7 Days") {
          try {
            await this.getLast7DaysCertificatesRequest().then((response) => {
              this.filterStatus = true;
              this.certificateHolder = this.certificateArray;
              this.folderHolder = this.folderArray;
              this.certificateArray = [];
              this.folderArray = [];
              this.certificateArray = response.data.data.certificate_list;
              this.spinnerStatus = false;
            });
          } catch (error) {
            this.spinnerStatus = false;
            this.filterIsSelected = "";
            this.isTimeRangeFiltersActive = false;
            this.$refs.toast.triggerToast(
              error.response.data.errors[0],
              "bottom-right",
              "error"
            );
          }
        } else {
          try {
            await this.getLast30DaysCertificatesRequest().then((response) => {
              this.filterStatus = true;
              this.certificateHolder = this.certificateArray;
              this.folderHolder = this.folderArray;
              this.certificateArray = [];
              this.folderArray = [];
              this.certificateArray = response.data.data.certificate_list;
              this.spinnerStatus = false;
            });
          } catch (error) {
            this.spinnerStatus = false;
            this.filterIsSelected = "";
            this.isTimeRangeFiltersActive = false;
            this.$refs.toast.triggerToast(
              error.response.data.errors[0],
              "bottom-right",
              "error"
            );
          }
        }
      } else {
        this.deactivateTimeFrameFilter();
      }
    },
    deactivateTimeFrameFilter() {
      this.filterIsSelected = "";
      this.folderArray = this.folderHolder;
      this.certificateArray = this.certificateHolder;
      this.filterStatus = false;
    },
    getData() {
      this.getTreeDataRequest().then((response) => {
        this.certificatesData = response.data.data.node_list;
        this.folderArray = this.certificatesData.children;
        this.certificateArray = this.certificatesData.certificates;
        this.middleName = this.certificatesData.name;
        this.currentParentId = this.certificatesData.id;
      });
    },
    async searchCertificate() {
      if (this.isDatePicked) {
        this.middleName = this.middleNameHolder;
        this.folderArray = this.folderHolder;
        this.certificateArray = this.certificateHolder;
      }
      this.spinnerStatus = true;
      this.removeFilterButtonStatus = false;
      try {
        let pickedDate = new Date(this.picked);
        this.pickedDateString = new Date(
          pickedDate.getTime() - pickedDate.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        this.middleNameHolder = this.middleName;
        this.middleName = this.pickedDateString;
        await this.getPickedDayCertificatesRequest(this.pickedDateString).then(
          (response) => {
            this.isDatePicked = true;
            this.certificateHolder = this.certificateArray;
            this.folderHolder = this.folderArray;
            this.folderArray = [];
            this.certificateArray = response.data.data.certificate_list;
          }
        );
      } catch (error) {
        this.middleName = this.middleNameHolder;
        this.isDatePicked = false;
        this.removeFilterButtonStatus = true;
        this.spinnerStatus = false;
        this.$refs.toast.triggerToast(
          error.response.data.errors[0],
          "bottom-right",
          "error"
        );
      }
    },
    removeFilter() {
      this.certificateArray = this.certificateHolder;
      this.folderArray = this.folderHolder;
      this.removeFilterButtonStatus = true;
      this.isDatePicked = false;
      this.middleName = this.middleNameHolder;
    },
  },
  created() {
    this.getAvailableDates()
      .then(() => {
        this.searchButtonStatus = false;
      })
      .catch((error) => {
        console.log(error);
      });
    this.getData();
  },
  mounted() {
    const maxPalette = this.colorPalettes.palettes.length;
    this.randomPalette = Math.floor(Math.random() * maxPalette);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300");
@import url("https://fonts.googleapis.com/css?family=Poppins|Oswald");

@keyframes RotateAnimation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes JiggleAnimation {
  0% {
    transform: rotate(-0.5deg);
  }

  50% {
    transform: rotate(0.5deg);
  }
}

@keyframes ActionButtonRightSlideAnimation {
  0% {
    transform: translateX(100px) scale(0.5);
  }

  100% {
    transform: translateX(0px) scale(0.5);
  }
}

@keyframes TopOpacityDisplayAnimation {
  from {
    transform: translate(-50%, -40px);
    opacity: 0;
  }

  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.container-div {
  width: 100vw;
  height: 100vh;
  margin: auto;
  border: none;
  box-sizing: border-box;
  font-family: "Poppins";
}

.container-title {
  width: 200px;
  height: auto;
  position: absolute;
  left: 10px;
  font-size: 30px;
  font-weight: bold;
}

.back-button-container {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 20px;
  border-radius: 100%;
  background-color: black;
  color: white;
  cursor: pointer;
}

.back-button {
  background-color: transparent;
  border: none;
  z-index: 99;
  margin-right: 10px;
}

.date-picker-div {
  width: calc(100% - 20px);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: right;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

#datePicker {
  width: 180px;
  border: none;
  height: 40px;
  background-color: white;
  box-shadow: 0 0 5px 1px #ced4da;
  outline: none;
  margin: 0;
  font-size: 14px;
  transition: 0.2s all;
}

#datePicker:hover:not([disabled]) {
  cursor: pointer;
}

.search-button {
  background-color: black;
  color: white;
  width: 100px;
  height: 40px;
  border: none;
  box-shadow: 0 0 5px 1px #ced4da;
  margin: 0 10px 0 0;
  z-index: 5;
  transition: 0.2s all;
}

.search-button:hover:not([disabled]) {
  cursor: pointer;
}

.certificate-main-container {
  width: 100%;
  height: calc(100vh);
  position: relative;
  transition: 1s all;
}

.certificate-node,
.folder-node {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: top left;
  cursor: pointer;
  background-color: white;
  transition: 0.5s all;
  z-index: 0;
  box-shadow: 0 0 5px 1px #ced4da;
}

.inside-node {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 15px;
  transition: 0.5s all;
  background-color: transparent;
  font-size: 12px;
  z-index: 4;
}

.folder-name {
  width: 200px;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  color: gray;
  transition: 0.2 all;
  text-align: center;
}

.action-button-container {
  width: 40px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 12.5%;
  transform: scale(3) translateY(-50%);
  transform-origin: top left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.certificate-action-button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.5) translateX(100px);
  transition: 0.2s all;
  animation: ActionButtonRightSlideAnimation 1s ease forwards;
}

.certificate-close-button {
  width: 30px;
  height: 30px;
  background-color: white;
  color: black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  position: absolute;
  top: calc(50% - 180px);
  left: calc(20% + 285px);
  transform: translateX(-50%);
}

.update-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.update-button {
  background-color: transparent;
  color: black;
  border: none;
  text-align: center;
}

.middle-title-certificate {
  width: 150px;
  height: 150px;
  background-color: black;
  color: white;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 1;
  text-align: center;
  transition: 0.5s all;
  line-height: 150px;
  box-shadow: 0 0 5px 1px #ced4da;
  font-size: 18px;
  user-select: none;
}

.root-folder-name {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.fade-in {
  animation: OpacityAnimation 1s ease;
}

.edit-button {
  width: 140px;
  height: 40px;
  margin: 10px 10px 10px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px 1px #ced4da;
  transition: 0.2s all;
  padding: 0 20px 0 20px;
  border: none;
  font-size: 14px;
  transition: 0.2s all;
}

.edit-button:hover:not([disabled]) {
  transform: scale(1.1);
}

.jiggle-animation {
  /* animation: JiggleAnimation 0.1s infinite; */
}

.expand-button {
  width: 120px;
  height: 40px;
  margin: 10px 10px 10px 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px 1px #ced4da;
  cursor: pointer;
  transition: 0.2s all;
  padding: 0 20px 0 20px;
  border: none;
  font-size: 14px;
}

.expand-button:hover {
  transform: scale(1.1);
}

.name-input {
  width: 80px;
  height: auto;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
  transition: 0.5s all;
  resize: none;
  border: 0.5px dashed black;
}

.pdf-container {
  width: calc(100% - 20px);
  height: 46px;
  position: absolute;
  top: 10px;
  animation: OpacityAnimation 2s ease;
  box-shadow: 0 0 5px 1px #545559;
}

.node-name {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.new-folder-button {
  width: 140px;
  height: 40px;
  border: none;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px 1px #ced4da;
  margin: 0 10px 0 0;
  padding: 0 20px 0 20px;
  font-size: 14px;
  transition: 0.2s all;
}

.new-folder-button:hover:not([disabled]) {
  transform: scale(1.1);
}

.new-folder-container {
  width: auto;
  height: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 5px 1px #ced4da;
  animation: TopOpacityDisplayAnimation 0.5s ease;
}

#new-folder-location-input {
  height: 40px;
  width: 100px;
  border: 1px solid lightgray;
  margin: 5px;
  outline: none;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  font-size: 12px;
}

#new-folder-name-input {
  height: 40px;
  width: 180px;
  border: 1px solid lightgray;
  margin: 5px 5px 5px 0;
  outline: none;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  font-size: 12px;
}

.location-text {
  margin: 0 10px 0 15px;
  font-size: 12px;
}

.time-filter-container {
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 5px 1px #ced4da;
  margin: 5px 10px 5px 0;
  transition: 0.2s all;
}

.filter-button {
  width: 100px;
  height: 30px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 1px #ced4da;
  border: none;
  font-size: 12px;
  background-color: white;
  transition: 0.2s all;
}

.path {
  width: auto;
  height: auto;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.save-new-folder-button {
  width: 70px;
  height: 40px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px 5px 5px 0;
  color: green;
  transition: 0.2s all;
  overflow: hidden;
  padding: 0;
}

.save-name-button {
  width: 100px;
  height: 40px;
  border: none;
  background-color: transparent;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px 5px 5px 0;
  color: green;
  transition: 0.2s all;
  overflow: hidden;
  padding: 0;
  transform: scale(0.5);
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) scale(0.5);
}

.back-to-main-button {
  width: auto;
  height: auto;
  border: 1px solid black;
  position: absolute;
  top: 70px;
  left: 20px;
  font-size: 14px;
  z-index: 5;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.warning-div {
  background-color: #fbe6e6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 200px;
}

.warning-text {
  display: block;
  margin-bottom: 10px;
}
</style>
