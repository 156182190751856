import paper from "paper";
function pen(parent, onDrawEnd, options) {
  function drawPath(option) {
    let path = initPath(Object.assign({}, options, option));
    let movPoint = null;
    parent.onMouseDown = (event) => {
      if (!path.closed) {
        path.lineTo(event.point);
        // console.log("Current path:", path.segments);
      }
      // console.log("pen is clicked:", event.point.x, event.point.y);
      // console.log("path.length:", path.curves.length);
      // if (path.curves.length == 0) {
      //   path.add(new paper.Point(event.point.x, event.point.y));
      // }
      // console.log("path.length after:", path.curves.length);
      // console.log("path.curves[0].points[0]:", path.curves[0].points[0]);
      if (path.curves.length > 0) {
        //close on loop back to first one
        let fPoint = path.curves[0].points[0];
        if (
          Math.abs(event.point.x - fPoint.x) < 5 &&
          Math.abs(event.point.y - fPoint.y) < 5 &&
          event.point.x != fPoint.x &&
          event.point.y != fPoint.y
        ) {
          path.closed = true;
          parent.onMouseMove();
          parent.onMouseDown = null;
          parent.onMouseMove = null;
          path.selected = false;
          if (onDrawEnd) onDrawEnd();
          if (option && option.onDrawEnd) option.onDrawEnd(path);
          return;
        }
      }
    };
    parent.onMouseMove = (event) => {
      if (movPoint) {
        path.removeSegment(path.segments.length - 1);
        movPoint = null;
      }
      if (!path.closed) {
        console.log("path.curves[0].points[0].x:", path.curves[0]);
        if (path.curves[0] != undefined) {
         
          let fPoint = path.curves[0].points[0];
          if (
            Math.abs(event.point.x - fPoint.x) < 5 &&
            Math.abs(event.point.y - fPoint.y) < 5 &&
            event.point.x != fPoint.x &&
            event.point.y != fPoint.y
          ) {
            path.fillColor = "rgba(0, 255, 0, 0.3)";
            const parentElement = document.getElementById('canvas'); // Replace with your parent element's actual ID
            parentElement.style.cursor = 'pointer';
          } else {
            path.fillColor = "rgba(0, 0, 0, 0.1)";
            const parentElement = document.getElementById('canvas'); // Replace with your parent element's actual ID
            parentElement.style.cursor = 'crosshair';
          }
        }
       
        let newPoint = event.point.clone();
        path.lineTo(newPoint);
        movPoint = newPoint;
      }
    };
    return path;
  }
  function drawisEmpty(
    option,
    width,
    height,
    startPoint = new paper.Point(1, 1)
  ) {
    let path = initPath(Object.assign({}, options, option));
    path.moveTo(startPoint);
    path.lineTo(new paper.Point(startPoint.x + width, startPoint.y));
    path.lineTo(new paper.Point(startPoint.x + width, startPoint.y + height));
    path.lineTo(new paper.Point(startPoint.x, startPoint.y + height));
    path.closed = true;

    if (onDrawEnd) onDrawEnd();
    if (option && option.onDrawEnd) option.onDrawEnd(path);
  }
  function drawRectangle(option) {
    let path = initPath(Object.assign({}, options, option));
    let startPoint = null;
    let movPoint = null;
    parent.onMouseDown = parent.onTouchStart = (event) => {
      parent.onMouseMove = null;
      if (!startPoint) {
        startPoint = event.point.clone();
        movPoint = null;
        if (path.segments.length === 0) {
          path.lineTo(startPoint);
        } else {
          path.segments[0].point = startPoint;
        }

        let width = 12;
        let height = 12;
        path.lineTo(new paper.Point(startPoint.x + width, startPoint.y));
        path.lineTo(
          new paper.Point(startPoint.x + width, startPoint.y + height)
        );
        path.lineTo(new paper.Point(startPoint.x, startPoint.y + height));
        path.closed = true;
      }
    };
    parent.onMouseDrag = parent.onTouchMove = (event) => {
      parent.onMouseDown = null;
      parent.onTouchStart = null;
      if (startPoint) {
        let newPos = event.point.clone();
        path.segments[1].point.x = newPos.x;
        path.segments[2].point = newPos;
        path.segments[3].point.y = newPos.y;
      }
    };
    // eslint-disable-next-line no-unused-vars
    parent.onMouseUp = (event) => {
      startPoint = null;
      parent.onMouseDrag = null;
      parent.onTouchMove = null;
      parent.onMouseUp = null;
      path.selected = false;
      path.fillColor = "rgba(0, 255, 0, 0.3)";
      if (onDrawEnd) onDrawEnd();
      if (option && option.onDrawEnd) option.onDrawEnd(path);
    };
    parent.onMouseMove = (event) => {
      if (!startPoint && !path.closed) {
        if (!movPoint) {
          let newPoint = event.point.clone();
          path.lineTo(newPoint);
          movPoint = newPoint;
        } else {
          path.segments[0].point.x = event.point.x;
          path.segments[0].point.y = event.point.y;
        }
      }
    };
  }
  function drawCircle(option) {
    let path = initPath(Object.assign({}, options, option));
    let startPoint = null;
    let movPoint = null;
    parent.onMouseDown = parent.onTouchStart = (event) => {
      parent.onMouseMove = null;
      if (!startPoint) {
        startPoint = event.point.clone();
        movPoint = null;
        if (path.segments.length === 0) {
          path.lineTo(startPoint);
        } else {
          path.segments[0].point = startPoint;
        }
        let center = new paper.Point(startPoint.x, startPoint.y);
        // let width = 12;
        // let height = 12;
        let radius = 10;
        for (let i = 0; i < 361; i = i + 10) {
          let x = center.x + radius * Math.sin((i * Math.PI) / 180);
          let y = center.y + radius * Math.cos((i * Math.PI) / 180);
          path.add(new paper.Point(x, y));
        }
        path.closed = true;
      }
    };
    // burası basıp büyütmek için yapılcak sıkıntılı kısım bu
    parent.onMouseDrag = parent.onTouchMove = (event) => {
      parent.onMouseDown = null;
      parent.onTouchStart = null;
      let newPos = event.point.clone();
      if (startPoint) {
        if (path.contains(newPos)) {
          path.scale(0.96);
        } else {
          path.scale(1.04);
        }
      }
    };
    // eslint-disable-next-line no-unused-vars
    parent.onMouseUp = (event) => {
      startPoint = null;
      parent.onMouseDrag = null;
      parent.onTouchMove = null;
      parent.onMouseUp = null;
      path.selected = false;
      path.fillColor = "rgba(0, 255, 0, 0.3)";
      if (onDrawEnd) onDrawEnd();
      if (option && option.onDrawEnd) option.onDrawEnd(path);
    };
    parent.onMouseMove = (event) => {
      if (!startPoint && !path.closed) {
        if (!movPoint) {
          let newPoint = event.point.clone();
          path.lineTo(newPoint);
          movPoint = newPoint;
        } else {
          path.segments[0].point.x = event.point.x;
          path.segments[0].point.y = event.point.y;
        }
      }
    };
  }
  function clearAllEvent() {
    parent.onMouseDown = null;
    parent.onTouchStart = null;
    parent.onMouseDrag = null;
    parent.onTouchMove = null;
    parent.onMouseMove = null;
    parent.onMouseUp = null;
  }
  function clear() {
    clearAllEvent();
    for (let j = 0; j < paper.project.layers.length; j++) {
      let layer = paper.project.layers[j];
      for (let i = layer.children.length - 1; i > 0; i--) {
        let item = layer.children[i];
        if (item instanceof paper.Path) {
          let item = layer.children[i];
          if (!item.closed) {
            item.remove();
          }
        }
      }
    }
  }
  function initPath(option) {
    let path = new paper.Path();
    path.option = option;
    path.name = option.name;
    path.closed = false;
    path.selected = option.selected || true;
    path.locked = option.locked || false;
    path.strokeColor = option.strokeColor;
    path.selectedColor = option.selectedColor;
    path.fillColor = option.fillColor;
    return path;
  }
  function draw(points, option) {
    let path = initPath(option);
    for (let i = 0; i < points.length; i++) {
      let point = points[i];
      path.lineTo(point);
    }
    path.closed = true;
    path.selected = false;
    return path;
  }
  return {
    clear,
    draw,
    drawPath,
    drawRectangle,
    drawCircle,
    drawisEmpty,
    initPath,
  };
}

export default pen;
