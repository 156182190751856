<template>
  <input autocomplete="off" type="text" id="Labels" class="filter-input" placeholder="Search and Select Labels"
    v-model="labelName" @input="handleFilter" @click="handleFocus(true)" />
  <button class="filter-button" @click="handleFilterButton">Filter</button>
  <div class="datalist" :style="{
    opacity: isFocused ? '1' : '0',
    transform: isFocused ? 'translateY(0)' : 'translateY(40px)',
    boxShadow: isFocused ? ' 0 0 10px 2px #ced4da' : 'none',
    pointerEvents: isFocused ? 'auto' : 'none',
  }" v-click-outside="onClickOutside">
    <div class="option-label" v-for="(label, idx) in labelNameArray" @click="handleCheckbox(label)" :key="idx">
      <input class="checkbox" type="checkbox" :value="label" v-model="selectedNamesArray" />
      <label>{{ label }} </label>
    </div>
  </div>
  <div class="selected-list" v-if="showLabelTags">
    <div v-for="(label, idx) in selectedNamesArray" :key="idx" :style="{
      animationDelay: idx / 10 + 's',
    }" class="label-tag">
      {{ label }}
      <i :style="{
        marginLeft: '5px',
        cursor: 'pointer',
        color: 'white',
      }" @click="handleClose(label)" class="bi bi-x"></i>
    </div>
  </div>
  <div class="view-button-container">
    <div :style="{ fontSize: '14px', margin: '5px 10px 5px 10px', color: 'gray' }">
      View:
    </div>
    <button class="change-view-button" @click="view = 'spiral'" :style="{
      backgroundColor: view === 'spiral' ? 'black' : 'white',
      color: view === 'spiral' ? 'white' : 'black',
    }">
      Spiral
    </button>
    <button class="change-view-button" @click="view = 'gallery'" :style="{
      right: '490px',
      backgroundColor: view === 'gallery' ? 'black' : 'white',
      color: view === 'gallery' ? 'white' : 'black',
    }">
      Gallery
    </button>
  </div>
  <button class="new-image-button" @click="addImageIsClicked = true">
    <i class="bi bi-plus-lg"></i> Add Image
  </button>

  <div class="library-container" :style="{
    filter: isFocused ? 'blur(5px)' : 'none',
  }">
    <div v-if="addImageIsClicked" class="action-container">
      <button class="close-button" @click="
        (addImageIsClicked = false),
        (showLabelsInAddImage = false),
        (labelNameInAddImage = '')
        ">
        <i class="bi bi-x"></i>
      </button>
      <button class="upload-button">
        <input type="file" class="upload-button" accept=".png,.jpg,.tiff,.jpeg,.svg" @change="getImageData"
          :style="{ position: 'absolute', opacity: '0' }" />
        <img v-if="!imageDataUrl" :style="{ width: '100px', height: '100px' }" src="./images/upload.png" alt="" />
        <img v-else :src="imageDataUrl" alt="" :style="{ width: '250px', height: '250px' }" />
      </button>
      <input type="text" class="filter-input-add-image-name"
        :style="{ position: 'relative', top: '10px', right: 0, margin: '10px' }" placeholder="Image Name"
        v-model="newImageName" />
      <input autocomplete="off" type="text" id="Labels" class="filter-input-add-image"
        placeholder="Search and Select Label" v-model="labelNameInAddImage" @input="handleFilterInAddImage"
        @click="showLabelsInAddImage = true" :style="{ position: 'relative', top: '20px', right: 0, margin: '10px' }" />
      <div class="datalist" :style="{
        opacity: showLabelsInAddImage ? '1' : '0',
        boxShadow: showLabelsInAddImage ? ' 0 0 10px 2px #ced4da' : 'none',
        pointerEvents: showLabelsInAddImage ? 'auto' : 'none',
        position: 'relative',
        top: '20px',
        right: '0px',
        width: '250px',
        maxHeight: '150px',
      }" v-click-outside="onClickOutsideAddImage">
        <div class="option-label-add-image" v-for="(label, idx) in labelNameArrayAddImage" @click="handleLabelInAddImage(label)"
          :key="idx" :style="{ height: showLabelsInAddImage ? '50px' : '0' }">
          <label>{{ label }} </label>
        </div>
      </div>

      <div class="new-image-button" @click="handleFileUpload" :style="{
        position: 'relative',
        top: '30px',
        right: '0',
      }">
        {{ errorOccured ? "Error!" : " Upload Image" }}
      </div>
    </div>

    <div v-if="isClicked && view === 'gallery'" class="action-container">
      <img v-if="isClicked" :src="isClicked.image_data" alt="" class="action-image" />
      <div v-if="isClicked" class="info-container">
        <div>Image Name: {{ isClicked.image_name }}</div>
        <div>Image ID: {{ isClicked.image_id }}</div>
        <div>Label ID: {{ isClicked.label_id }}</div>
      </div>
      <button class="delete-button" @click="handleDelete(isClicked.image_id)" :style="{ left: '150px' }">
        <i class="bi bi-trash3" :style="{ color: 'red' }"></i> Delete
      </button>
      <button class="close-button" @click="isClicked = null">
        <i class="bi bi-x"></i>
      </button>
    </div>

    <div class="image-spiral" :style="{
      display: view === 'spiral' ? 'block' : 'flex',
      flexWrap: view === 'sprial' ? 'none' : 'wrap',
      overflowY: view === 'gallery' ? 'scroll' : '',
      height:
        view === 'spiral'
          ? '100%'
          : filteredImageList.length > 32
            ? '100%'
            : 'auto',
    }">
      <div class="middle-title" v-if="view === 'spiral' && !isLoading" :style="{ left: isClicked ? '75%' : '50%' }">
        {{ filteredImageList.length }} Images
      </div>

      <img class="image" v-for="(image, idx) in filteredImageList" :key="idx" :src="image.image_data"
        @mouseenter="isHovered = idx" @mouseleave="isHovered = null" @click="isClicked = image" :style="{
          transform:
            view === 'spiral'
              ? isClicked === image
                ? 'rotate(0)'
                : 'rotate(' +
                idx * (360 / filteredImageList.length) +
                'deg) translate(' +
                (filteredImageList.length > 50
                  ? '30'
                  : filteredImageList.length / 2) +
                '%, 50%)' +
                (isHovered === idx ? 'scale(1.2)' : '')
              : '',
          zIndex: view === 'spiral' ? (isHovered === idx ? '2' : '1') : '',
          filter: addImageIsClicked
            ? 'blur(10px)'
            : view === 'spiral'
              ? isHovered === idx || isHovered === null
                ? 'none'
                : isClicked === image
                  ? 'none'
                  : 'blur(5px)'
              : isClicked === image || isClicked === null
                ? ''
                : 'blur(10px)',
          left:
            view === 'spiral'
              ? isClicked === image
                ? '12.5%'
                : isClicked !== null
                  ? '75%'
                  : '50%'
              : '0',
          top: view === 'spiral' ? (isClicked === image ? '20%' : '50%') : '0',
          width:
            view === 'spiral'
              ? isClicked === image
                ? '300px'
                : '130px'
              : '12.5%',
          height:
            view === 'spiral'
              ? isClicked === image
                ? '300px'
                : '130px'
              : '130px',
          pointerEvents:
            isClicked === image || addImageIsClicked || isFocused
              ? 'none'
              : 'auto',
          position: view === 'spiral' ? 'absolute' : 'relative',
        }" />
      <button class="close-button" v-if="isClicked && view === 'spiral'" :style="{
        top: '10%',
        left: '25%',
        backgroundColor: 'black',
        color: 'white',
      }" @click="isClicked = null">
        <i class="bi bi-x"></i>
      </button>
      <div v-if="isClicked && view === 'spiral'" :style="{
        position: 'absolute',
        bottom: '15%',
        left: '12.5%',
      }" class="info-container">
        <div>Image Name: {{ isClicked.image_name }}</div>
        <div>Image ID: {{ isClicked.image_id }}</div>
        <div>Label ID: {{ isClicked.label_id }}</div>
      </div>
      <button class="delete-button" @click="handleDelete(isClicked.image_id)" v-if="isClicked && view === 'spiral'"
        :style="{ bottom: '5%' }">
        <i class="bi bi-trash3" :style="{ color: 'red' }"></i> Delete
      </button>
    </div>
    <button class="load-more-button" :style="{
      left: isClicked ? '75%' : '50%',
      display: view === 'spiral' ? 'block' : 'none',
      backgroundColor: isLoading ? 'lightgray' : 'white',
      opacity: isLoading ? '0.5' : '1',
      pointerEvents: isLoading ? 'none' : 'auto',
      animation: isLoading ? 'LoadingAnimation 1s ease infinite' : 'none',
    }" @click="(startIndex += limit), getImages()">
      {{ isLoading ? "Loading" : " Load More" }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useLibraryStore } from "@/store/modules/admin/library";
import vClickOutside from "click-outside-vue3";

export default {
  data() {
    return {
      labelList: [],
      labelNameArray: [],
      selectedLabelIDArray: [],
      selectedLabels: [],
      selectedNamesArray: [],
      labelName: "",
      labelNameInAddImage: "",
      isFocused: false,
      showLabelTags: false,
      limit: 10,
      startIndex: 0,
      newLabelIdList: [],
      libraryList: [],
      imageList: [],
      isHovered: null,
      isClicked: null,
      view: "spiral",
      addImageIsClicked: false,
      showLabelsInAddImage: false,
      selectedLabelInAddImage: "",
      isLoading: true,
      filteredImages: [],
      imageDataUrl: null,
      newImageName: null,
      errorOccured: false,
      textColor: "",
      labelNameArrayAddImage: [],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    filteredImageList() {
      if (!this.showLabelTags) {
        return this.imageList;
      }
      return this.imageList.filter((image) =>
        this.selectedLabelIDArray.includes(image.label_id)
      );
    },
  },
  methods: {
    ...mapActions(useLibraryStore, [
      "fetchImagesDataRequest",
      "fetchLabelDataRequest",
      "deleteLibraryItemRequest",
      "uploadImageRequest",
    ]),

    onClickOutside(event) {
      const element = event.target.className;
      if (element === "option-label" || element === "filter-input") {
        this.isFocused = true;
      } else {
        this.isFocused = false;
      }
    },
    onClickOutsideAddImage(event) {
      const element = event.target.className;
      if (element === "option-label-add-image" || element === "filter-input-add-image") {
        this.showLabelsInAddImage = true;
      } else {
        this.showLabelsInAddImage = false;
      }
    },
    getImages() {
      this.isLoading = true;
      return new Promise((resolve) => {
        this.fetchImagesDataRequest({
          limit: this.limit,
          start_index: this.startIndex,
          label_id_list: this.newLabelIdList,
        }).then((response) => {
          response.data.data.library_list.forEach((item) => {
            this.imageList.push(item);
            this.isLoading = false;
          });
        });
        resolve();
      });
    },
    getLabels() {
      return new Promise((resolve) => {
        this.fetchLabelDataRequest().then((response) => {
          this.labelList = response.data.data.label_result;
          this.labelList.forEach((item) => {
            this.labelNameArray.push(item.label_name);
          });
          this.labelNameArrayAddImage = [...this.labelNameArray];
          resolve();
        });
      });
    },
    handleCheckbox(labelChecked) {
      if (this.selectedNamesArray.includes(labelChecked)) {
        this.selectedNamesArray = this.selectedNamesArray.filter(
          (label) => label != labelChecked
        );
      } else {
        this.selectedNamesArray.push(labelChecked);
      }
    },
    handleLabelInAddImage(labelName) {
      this.selectedLabelInAddImage = labelName;
      this.showLabelsInAddImage = false;
      this.labelNameInAddImage = labelName;
    },
    handleFilter() {
      this.labelNameArray = [];
      for (let i = 0; i < this.labelList.length; i++) {
        const currentName = this.labelList[i].label_name;
        if (
          currentName.includes(this.labelName) &&
          !this.labelNameArray.includes(currentName)
        ) {
          this.labelNameArray.push(currentName);
        }
      }
    },
    handleFilterInAddImage() {
      this.labelNameArrayAddImage = [];
      for (let i = 0; i < this.labelList.length; i++) {
        const currentName = this.labelList[i].label_name;
        if (
          currentName.includes(this.labelNameInAddImage) &&
          !this.labelNameArrayAddImage.includes(currentName)
        ) {
          this.labelNameArrayAddImage.push(currentName);
        }
      }
    },
    handleFocus(focus) {
      this.isFocused = focus;
      this.showLabelTags = false;
    },
    handleFilterButton() {
      this.isFocused = false;
      if (this.labelName === "" && this.selectedNamesArray.length === 0) {
        this.showLabelTags = false;
      } else {
        this.showLabelTags = true;
        this.labelList.forEach((label) => {
          this.selectedNamesArray.forEach((name) => {
            if (name === label.label_name) {
              if (!this.selectedLabelIDArray.includes(label.label_id)) {
                this.selectedLabelIDArray.push(label.label_id);
              }
            }
          });
        });
      }
    },
    handleClose(removeLabelName) {
      this.selectedNamesArray = this.selectedNamesArray.filter(
        (item) => item !== removeLabelName
      );
      let removeId = this.labelList.filter(
        (label) => label.label_name === removeLabelName
      )[0].label_id;
      this.selectedLabelIDArray = this.selectedLabelIDArray.filter(
        (id) => id !== removeId
      );
      if (this.selectedNamesArray.length === 0) {
        this.showLabelTags = false;
      }
    },
    handleDelete(imageID) {
      this.isClicked = null;
      return new Promise((resolve) => {
        this.deleteLibraryItemRequest(imageID).then(
          (this.imageList = this.imageList.filter(
            (item) => item.image_id !== imageID
          ))
        );
        resolve();
      });
    },
    handleFileUpload() {
      this.labelList.forEach((label) => {
        if (
          label.label_name === this.labelNameInAddImage &&
          !this.newLabelIdList.includes(label.label_id)
        ) {
          this.newLabelIdList.push(label.label_id);
          console.log(this.newLabelIdList);
        }
      });
      this.uploadImageRequest({
        image_name: this.newImageName,
        label_id_list: this.newLabelIdList,
        image_data: this.imageDataUrl,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.json());
          }
        })
        .then(() => {
          this.newImageName = null;
          this.newLabelIdList = [];
          this.labelNameInAddImage = "";
          this.imageDataUrl = null;
          this.addImageIsClicked = false;
          this.imageList = [];
          this.getImages();
        })
        .catch(() => {
          this.message = "Error occured. Please try again";
          this.textColor = "red";
          this.$emit("message", this.message, this.textColor);
        });
    },
    getImageData(event) {
      const selectedImage = event.target.files[0];

      if (selectedImage) {
        if (selectedImage.type.match(/.(png|jpg|jpeg|bmp|tiff|tif)$/i)) {
          console.log(selectedImage);
          if (selectedImage.size <= 31457280) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imageDataUrl = e.target.result;
            };
            reader.readAsDataURL(selectedImage);
          }
        }
      }
    },
  },
  mounted() {
    this.getLabels();
    this.getImages();
  },
};
</script>

<style scoped>
.library-container {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 2px #ced4da;
  animation: SlideAnimation 0.8s ease;
  transition: all 0.5s;
  position: relative;
  padding: 0;
  overflow: hidden;
}

.filter-input, .filter-input-add-image, .filter-input-add-image-name {
  width: 250px !important;
  height: 40px !important;
  padding: 0 10px 0 10px !important;
  box-shadow: 0 0 10px 2px #ced4da;
  border: none !important;
  margin: 0 !important;
  position: absolute;
  top: 70px;
  right: 140px;
  font-size: 13px;
  outline: none;
}

.filter-button {
  width: 100px;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 40px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: black;
  color: white;
  transition: 0.2s all;
}
.option-label, .option-label-add-image {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  font-size: 14px;
  transition: 0.2s all;
}
.filter-button:hover {
  font-size: 12px;
}

.datalist {
  width: 350px;
  height: auto;
  max-height: 400px;
  position: absolute;
  top: 120px;
  right: 40px;
  z-index: 3;
  background-color: white;
  overflow-y: scroll;
  box-shadow: 0 0 10px 2px #ced4da;
  transition: 0.5s all;
}

.option-label:hover, .option-label-add-image:hover {
  background-color: black;
  color: white;
}

.checkbox {
  cursor: pointer;
  margin-right: 20px;
}

label {
  cursor: pointer;
}

.selected-list {
  width: auto;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 40px;
  display: flex;
  overflow: hidden;
  padding-left: 20px;
  transition: 1s all;
}

.label-tag {
  width: auto;
  height: 40px;
  padding: 0 10px 0 10px;
  margin: 0 3px 0 3px;
  box-sizing: border-box;
  background-color: black;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: LeftSlideAnimation 1s forwards;
}

.image-spiral {
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.5s all;
  position: relative;
}

.image {
  width: 130px;
  height: 130px;
  position: absolute;
  border: 1px solid lightgray;
  transform-origin: top left;
  left: 50%;
  top: 25%;
  transition: 0.5s all;
  cursor: pointer;
}

.middle-title {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transition: all 0.5s;
  transform: translateX(-50%);
  font-size: 14px;
}

.view-button-container {
  width: auto;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 400px;
  background-color: white;
  box-shadow: 0 0 10px 2px #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.change-view-button {
  width: 80px;
  height: 30px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: black;
  color: white;
  transition: 0.2s all;
  font-size: 14px;
  z-index: 3;
  margin: 5px;
}

.action-container {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px 2px #ced4da;
  animation: OpacityAnimation 0.8s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  padding: 50px;
}

.action-image {
  width: 300px;
  height: 300px;
}

.info-container {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 10px;
}

.delete-button {
  width: 100px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  left: calc(12.5% + 100px);
  border: 0.5px solid lightgray;
  background-color: white;
  color: black;
  transition: 0.2s all;
  font-size: 14px;
}

.delete-button:hover {
  box-shadow: 0 0 10px 2px lightgray;
  transform: scale(1.05);
}

.close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0.5px solid lightgray;
  background-color: white;
  color: black;
  transition: 0.2s all;
  font-size: 14px;
}

.close-button:hover {
  box-shadow: 0 0 10px 2px lightgray;
  transform: scale(1.05);
}

.new-image-button {
  width: 150px;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 645px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: lightgray;
  color: black;
  transition: 0.2s all;
  font-size: 14px;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.new-image-button:hover {
  background-color: black;
  color: white;
}

.upload-button {
  width: 250px;
  height: 250px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 0.2s all;
}

.upload-button:hover {
  background-color: black;
  border: 1px dashed white;
}

.load-more-button {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: none;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  font-size: 14px;
  transition: 0.5s all;
  box-shadow: 0 0 10px 2px #ced4da;
}
</style>
