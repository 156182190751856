<template>
  <input
    autocomplete="off"
    type="text"
    id="companies"
    class="filter-input"
    placeholder="Search and Select Companies"
    v-model="companyName"
    @input="handleFilter"
    @click="handleFocus(true)"
  />
  <button class="filter-button" @click="handleButton">Filter</button>
  <div
    class="datalist"
    :style="{
      opacity: isFocused ? '1' : '0',
      transform: isFocused ? 'translateY(0)' : 'translateY(40px)',
      boxShadow: isFocused ? ' 0 0 10px 2px #ced4da' : 'none',
    }"
  >
    <div
      class="option-company"
      v-for="(company, idx) in companyNameArray"
      @click="handleCheckbox(company)"
      :key="idx"
    >
      <input
        class="checkbox"
        type="checkbox"
        :value="company"
        v-model="selectedCompanies"
      />
      <label>{{ company }} </label>
    </div>
  </div>
  <div class="selected-list" v-if="showCompanyTags">
    <div
      v-for="(company, idx) in selectedCompanies"
      :key="idx"
      :style="{
        animationDelay: idx / 10 + 's',
      }"
      class="company-tag"
    >
      {{ company }}
      <i
        :style="{
          marginLeft: '5px',
          cursor: 'pointer',
          color: 'white',
        }"
        @click="handleClose(company)"
        class="bi bi-x"
      ></i>
    </div>
  </div>
  <div
    class="history-container"
    :style="{
      filter: isFocused ? 'blur(5px)' : 'none',
      pointerEvents: isFocused ? 'none' : 'auto',
    }"
  >
    <div class="label-row">
      <div class="label">Company Name</div>
      <div class="label">Creation Time</div>
      <div class="label">Old Max Limit Count</div>
      <div class="label">New Max Limit Count</div>
      <div class="label">Modified Time</div>
    </div>
    <div
      v-for="(company, idx) in filteredCreditList"
      :key="idx"
      class="company-row"
    >
      <div class="value">{{ company.company_name }}</div>
      <div class="value">
        {{ company.created.substring(0, company.created.length - 7) }}
      </div>
      <div class="value" :style="{ color: 'red' }">
        {{ company.limitation_count_max_old }}
      </div>
      <div class="value" :style="{ color: 'green' }">
        {{ company.limitation_count_max_new }}
      </div>
      <div class="value">
        {{ company.modified.substring(0, company.modified.length - 7) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useCompanyStore } from "@/store/modules/admin/company";

export default {
  data() {
    return {
      companyArray: [],
      limitationList: [],
      companyNameArray: [],
      selectedCompanies: [],
      companyName: "",
      isFocused: false,
      showCompanyTags: false,
    };
  },
  methods: {
    ...mapActions(useCompanyStore, [
      "fetchCompanyLimitationData",
      "fetchCompanyDataRequest",
    ]),

    getCompanies() {
      return new Promise((resolve) => {
        this.fetchCompanyDataRequest({}).then((response) => {
          response.data.data.company_list.forEach((company) => {
            this.companyArray.push({
              id: company.id,
              company_name: company.company_name,
            });
            this.companyIdArray = this.companyArray.map(
              (company) => company.id
            );
          });
          resolve();
        });
      });
    },

    getCredits(idArray) {
      this.fetchCompanyLimitationData({ 'company_id_list': idArray }).then(
        (response) => {
          this.limitationList = response.data.data.limitation_list;
          this.companyNameArray = Array.from(
            new Set(this.companyArray.map((company) => company.company_name))
          );
        }
      );
    },

    handleCheckbox(companyName) {
      console.log(companyName);
      if (this.selectedCompanies.includes(companyName)) {
        this.selectedCompanies = this.selectedCompanies.filter(
          (name) => name != companyName
        );
      } else {
        this.selectedCompanies.push(companyName);
      }
      console.log(this.selectedCompanies);
    },

    handleFilter() {
      this.companyNameArray = [];
      for (let i = 0; i < this.limitationList.length; i++) {
        const currentName = this.limitationList[i].company_name;
        if (
          currentName.includes(this.companyName) &&
          !this.companyNameArray.includes(currentName)
        ) {
          this.companyNameArray.push(currentName);
        }
      }
    },

    handleFocus(focus) {
      this.isFocused = focus;
      this.showCompanyTags = false;
    },

    handleButton() {
      this.isFocused = false;

      if (this.companyName === "" && this.selectedCompanies.length === 0) {
        this.showCompanyTags = false;
      } else {
        this.showCompanyTags = true;
      }
    },

    handleClose(removeItem) {
      console.log(removeItem);
      this.selectedCompanies = this.selectedCompanies.filter(
        (item) => item !== removeItem
      );
      if (this.selectedCompanies.length === 0) {
        this.showCompanyTags = false;
      }
    },
  },

  computed: {
    filteredCreditList() {
      if (!this.showCompanyTags) {
        return this.limitationList;
      }
      return this.limitationList.filter((company) =>
        this.selectedCompanies.includes(company.company_name)
      );
    },
  },

  mounted() {
    this.getCompanies().then(() => {
      this.getCredits(this.companyIdArray);
    });
  },
};
</script>

<style scoped>
.history-container {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 2px #ced4da;
  animation: SlideAnimation 0.8s ease;
  overflow-y: scroll;
  transition: all 0.5s;
}

.label-row {
  width: 100%;
  height: 50px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  padding: 0 0 0 30px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 2;
}

.label {
  width: 18%;
  font-size: 14px;
  color: black;
  margin-right: 2%;
  padding: 5px;
}

.company-row {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ced4da;
  display: flex;
  align-items: center;
  padding: 0 0 0 30px;
  box-sizing: border-box;
  position: relative;
  transition: 0.2s all;
}

.value {
  width: 18% !important;
  font-size: 14px;
  height: 30px !important;
  outline: none !important;
  background-color: white;
  margin-right: 2% !important;
  padding: 5px !important;
  border: none;
}

.filter-input {
  width: 250px !important;
  height: 40px !important;
  padding: 0 10px 0 10px !important;
  box-shadow: 0 0 10px 2px #ced4da;
  border: none !important;
  margin: 0 !important;
  position: absolute;
  top: 70px;
  right: 140px;
  font-size: 13px;
  outline: none;
}

.filter-button {
  width: 100px;
  height: 40px;
  position: absolute;
  top: 70px;
  right: 40px;
  border: none;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: black;
  color: white;
  transition: 0.2s all;
}

.filter-button:hover {
  font-size: 12px;
}

.datalist {
  width: 350px;
  height: auto;
  max-height: 400px;
  position: absolute;
  top: 120px;
  right: 40px;
  z-index: 3;
  background-color: white;
  overflow-y: scroll;
  box-shadow: 0 0 10px 2px #ced4da;
  transition: 0.5s all;
}

.option-company {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  font-size: 14px;
  transition: 0.2s all;
}

.option-company:hover {
  background-color: black;
  color: white;
}

.checkbox {
  cursor: pointer;
  margin-right: 20px;
}

label {
  cursor: pointer;
}

.selected-list {
  width: auto;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 40px;
  display: flex;
  overflow: hidden;
  padding-left: 20px;
  transition: 1s all;
}

.company-tag {
  width: auto;
  height: 40px;
  padding: 0 10px 0 10px;
  margin: 0 3px 0 3px;
  box-sizing: border-box;
  background-color: black;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: LeftSlideAnimation 1s forwards;
}
</style>
