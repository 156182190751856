import axios from "axios";
import { defineStore } from "pinia";

export const useCertificateNodeStore = defineStore("certificateStore", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async getTreeDataRequest() {
      try {
        const url = "/certificates/nodes/get-tree-data";

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error getting tree data:", error);
        throw error;
      }
    },
    async moveNodeItemRequest(payload) {
      try {
        const url = "/certificates/nodes/move-node-item";

        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error moving node item:", error);
        throw error;
      }
    },
    async moveNodeRequest(payload) {
      try {
        const url = "/certificates/nodes/move-node";

        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error moving node:", error);
        throw error;
      }
    },
    async updateNodeNameRequest(payload) {
      try {
        const url = "/certificates/nodes/update-node-name";

        const response = await axios.post(url, payload);
        return response;
      } catch (error) {
        console.error("Error moving node:", error);
        throw error;
      }
    },
    async deleteNodeRequest(nodeId) {
      try {
        const url = `/certificates/nodes/delete-node/${nodeId}`;
        
        const response = await axios.delete(url);
        return response;
      } catch (error) {
        console.error("Error while deleting node:", error);
        throw error;
      }
    },
  },
});
