/* eslint-disable no-unused-vars */
const { minWeightAssign, maxWeightAssign } = require('munkres-algorithm');
function colorSVG() {
  const trendcolors = require("../json/trendcolors.json");
  function groupSvgFillsByColorDifference(paths, colorDifference) {
    const fillColors = [];
    paths.forEach((path) => {
      const fill = path.fill;
      if (fill) {
        fillColors.push(fill);
      }
    });

    const colorGroups = new Map();
    fillColors.forEach((color1, i) => {
      for (let j = i + 1; j < fillColors.length; j++) {
        const color2 = fillColors[j];
        if (colorDifferenceBetweenColors(color1, color2) <= colorDifference) {
          if (!colorGroups.has(i)) {
            colorGroups.set(i, []);
          }
          if (!colorGroups.has(j)) {
            colorGroups.set(j, []);
          }
          colorGroups.get(i).push(j);
          colorGroups.get(j).push(i);
        }
      }
    });

    const colorGroupsList = Array.from(getColorGroups(colorGroups), (group) =>
      Array.from(group)
    );
    return colorGroupsList;
  }

  function getColorGroups(colorGroups) {
    const visited = new Set();
    const groups = [];

    function dfs(node, newGroup) {
      visited.add(node);
      newGroup.add(node);
      for (const neighbor of colorGroups.get(node)) {
        if (!visited.has(neighbor)) {
          dfs(neighbor, newGroup);
        }
      }
    }

    for (const node of colorGroups.keys()) {
      if (!visited.has(node)) {
        const newGroup = new Set();
        dfs(node, newGroup);
        groups.push([...newGroup]);
      }
    }

    return groups;
  }

  function colorDifferenceBetweenColors(color1, color2) {
    // Convert the colors from hex strings to RGB tuples
    const r1 = parseInt(color1.substr(1, 2), 16);
    const g1 = parseInt(color1.substr(3, 2), 16);
    const b1 = parseInt(color1.substr(5, 2), 16);
    const r2 = parseInt(color2.substr(1, 2), 16);
    const g2 = parseInt(color2.substr(3, 2), 16);
    const b2 = parseInt(color2.substr(5, 2), 16);

    // Convert the RGB tuples to HSV tuples
    const [h1, s1, v1] = rgbToHsv(r1 / 255, g1 / 255, b1 / 255);
    const [h2, s2, v2] = rgbToHsv(r2 / 255, g2 / 255, b2 / 255);

    // Calculate the color difference using the HSV values
    const colorDifference =
      Math.abs(h1 - h2) * 360 +
      Math.abs(s1 - s2) * 100 +
      Math.abs(v1 - v2) * 100;
    return colorDifference;
  }

  function rgbToHsv(r, g, b) {
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;
    let h, s, v;

    if (max === 0) {
      s = 0;
    } else {
      s = delta / max;
    }

    if (max === min) {
      h = 0;
    } else if (r === max) {
      h = (g - b) / delta;
    } else if (g === max) {
      h = 2 + (b - r) / delta;
    } else {
      h = 4 + (r - g) / delta;
    }

    h = h < 0 ? h + 6 : h;
    h = h / 6;

    return [h, s, max];
  }

  function closestColor(color, colors) {
    let diff = 100000;
    let best = null;
    for (let i = 0; i < colors.length; i++) {
      const d =
        Math.abs(color[0] - colors[i][0]) +
        Math.abs(color[1] - colors[i][1]) +
        Math.abs(color[2] - colors[i][2]);
      if (d < diff) {
        diff = d;
        best = i;
      }
    }
    return [colors[best], diff];
  }

  function rgbToHex(rgb) {
    return (
      "#" +
      ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
        .toString(16)
        .slice(1)
    );
  }
  function hexToRgb(hex) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    return [r, g, b];
  }
  function closestColorforUsePalette(color, colors) {
    let diff = 100000;
    let best = null;
    for (let i = 0; i < colors.length; i++) {
      const d =
        Math.abs(color[0] - colors[i][0]) +
        Math.abs(color[1] - colors[i][1]) +
        Math.abs(color[2] - colors[i][2]);
      if (d < diff) {
        diff = d;
        best = i;
      }
    }
    return [colors[best], diff, colors.splice(best, 1)];
  }
  function calculateAllColorDifferencesasList(firstColorList, secondColorList) {
    const colorDifferences = [];

    for (const color1 of firstColorList) {
      const differencesForColor1 = [];
      for (const color2 of secondColorList) {
        const difference = euclideanDistance(color1, color2);
        differencesForColor1.push(difference);
      }
      colorDifferences.push(differencesForColor1);
    }
   
    return createMatrix(colorDifferences);
  }
  function euclideanDistance(color1, color2) {
    const r1 = color1[0];
    const g1 = color1[1];
    const b1 = color1[2];
    const r2 = color2[0];
    const g2 = color2[1];
    const b2 = color2[2];

    const dr = r1 - r2;
    const dg = g1 - g2;
    const db = b1 - b2;

    return Math.sqrt(dr * dr + dg * dg + db * db);
  }
  function createMatrix(differences) {
    const numRows = differences.length;
    const numCols = differences[0].length;

    const matrix = [];

    for (let i = 0; i < numRows; i++) {
      const row = [];
      for (let j = 0; j < numCols; j++) {
        row.push(differences[i][j]);
      }
      matrix.push(row);
    }

    return matrix;
  }

  function assignColorsUsingMinAssignment(firstColorList, secondColorList, minAssignment) {
    const assignedColors = [];

    for (let i = 0; i < firstColorList.length; i++) {
      const assignedIndex = minAssignment[i];

      if (assignedIndex !== null) {
        assignedColors.push(secondColorList[assignedIndex]);
      } else {
          const [newColor,dif] = closestColor(
          firstColorList[i],
          secondColorList
        );
        assignedColors.push(newColor); 
      }
    }

    return assignedColors;
  }
  function colorChange(
    gtags,
    trendColorPalettevalues
  ) {
    let trendColorPalette = trendColorPalettevalues.slice();
    var trendColorPaletteRGB = trendColorPalette.map((color) =>
      hexToRgb(color)
    );
    var FilledColorList = []
    for (const item of gtags) {
        FilledColorList.push(item.fill.match(/\d+/g).map(Number))
    }
    var ListofDifferences = calculateAllColorDifferencesasList(FilledColorList, trendColorPaletteRGB);

    const minAssignment = minWeightAssign(ListofDifferences);

    var AssignedColors = assignColorsUsingMinAssignment(FilledColorList, trendColorPaletteRGB, minAssignment.assignments);
    console.log("AssignedColors", AssignedColors);
    for (let i = 0; i < gtags.length; i++) {
      const item = gtags[i];
      
      const assignedColor = AssignedColors[i];
      
      const filledColor = `rgb(${assignedColor[0]}, ${assignedColor[1]}, ${assignedColor[2]})`;

      item.fill = filledColor;
    }
 
    return gtags;
  }
  function is_contains(color_groups, id, group_index) {
    // Check if the given ID is present in the specified color group
    for (let i = 0; i < group_index.length; i++) {
      if (group_index[i] === id) {
        return true;
      }
    }
    return false;
  }
  function getColorValues(colorName, json) {
    const colorObj = json.colors.find((color) => color.name === colorName); // Find the object with the matching color name
    if (colorObj) {
      // If the color object is found
      return colorObj.values; // Return the corresponding color values
    }
    return null; // Return null if the color name is not found
  }
  function getShadesOfColor(hexColor) {
    // Remove the '#' symbol if it's included
    let originVal = hexColor;
    if (hexColor[0] === "#") {
      hexColor = hexColor.slice(1);
    }

    // Convert the hex color to RGB
    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);

    // Calculate the shades
    const shades = [];
    for (let i = 1; i <= 5; i++) {
      const percent = i * 0.1;
      const shadeRed = Math.floor(red * (1 - percent));
      const shadeGreen = Math.floor(green * (1 - percent));
      const shadeBlue = Math.floor(blue * (1 - percent));
      const shadeHex = `#${(shadeRed < 16 ? "0" : "") + shadeRed.toString(16)}${
        (shadeGreen < 16 ? "0" : "") + shadeGreen.toString(16)
      }${(shadeBlue < 16 ? "0" : "") + shadeBlue.toString(16)}`;
      shades.unshift(shadeHex);
    }
    for (let i = 1; i <= 5; i++) {
      const percent = i * 0.1;
      const tintRed = Math.floor(red + (255 - red) * percent);
      const tintGreen = Math.floor(green + (255 - green) * percent);
      const tintBlue = Math.floor(blue + (255 - blue) * percent);
      const tintHex = `#${(tintRed < 16 ? "0" : "") + tintRed.toString(16)}${
        (tintGreen < 16 ? "0" : "") + tintGreen.toString(16)
      }${(tintBlue < 16 ? "0" : "") + tintBlue.toString(16)}`;
      shades.push(tintHex);
    }
    shades.push(originVal);
    return shades;
  }
  function colorChangewithDrag(
    selectedGroupindex,
    paths,
    ApliedColors
  ) {
    let trendColorPalette = ApliedColors;
    const trendColorPaletteRGB = trendColorPalette.map((color) =>
      hexToRgb(color)
    );

    for (const path of paths) {
      if (path.id == selectedGroupindex) {
        const rgbArray = path.fill
        .match(/\d+/g) // Match one or more digits
        .map(Number);  // Convert the matched strings to numbers

        const fillRGB = rgbArray;
        const [newColor,dif] = closestColor(
          fillRGB,
          trendColorPaletteRGB
        );
        const rgbString = `rgb(${newColor.join(', ')})`;
        path.fill = rgbString;
      }
    }
    return paths;
  }
  // function is_contains_forDrag(color_groups, id, group_index) {
  //   // Check if the given ID is present in the specified color group
  //   const group = color_groups[group_index];
  //   for (let i = 0; i < group.length; i++) {
  //     if (group[i] === id) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  function useColorPalette(
    gtags,
    ColorPalettevalues,
  ) {
    let coloredPaths = colorChange(
      gtags,
      ColorPalettevalues
    );
    return coloredPaths;
  }
  return {
    groupSvgFillsByColorDifference,
    getColorValues,
    getColorGroups,
    colorDifferenceBetweenColors,
    rgbToHsv,
    hexToRgb,
    closestColor,
    rgbToHex,
    colorChange,
    colorChangewithDrag,
    is_contains,
    getShadesOfColor,
    useColorPalette,
  };
}

export default colorSVG;
