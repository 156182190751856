import { useAuthenticationStore } from "@/store/modules/auth";
import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../views/IndexView.vue";
import Login from "../views/Auth/LoginView.vue";
import Admin from "../views/Admin/AdminView.vue";
import CertificateApp from "../views/Design/CertificateApp.vue";
import TextToImage from "../views/Design/TextToImage.vue";
import MultiImageToDesign from "../views/Design/MultiImageToDesign.vue";
import ImageAndTextToDesign from "../views/Design/ImageAndTextToDesign.vue";
import QuestionsAndAnswerApp from "../views/Design/QuestionsAndAnswerApp.vue";
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "home",
    component: Index,
    meta: { requiresAuth: true },
  },
  {
    path: "/certificates",
    name: "certificate",
    component: CertificateApp,
    meta: { requiresAuth: true },
  },
  {
    path: "/texttodesign",
    name: "textToDesign",
    component: TextToImage,
    meta: { requiresAuth: true },
  },
  {
    path: "/multiimagetodesign",
    name: "multiImageToDesign",
    component: MultiImageToDesign,
    meta: { requiresAuth: true },
  },
  {
    path: "/imageandtexttodesign",
    name: "imageAndTextToDesign",
    component: ImageAndTextToDesign,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/questionsandanswer",
    name: "questionsandanswer",
    component: QuestionsAndAnswerApp,
    meta: { requiresAuth: true},
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const authStore = useAuthenticationStore();
  if ((!authStore.user || !authStore.status) && to.meta.requiresAuth) {
    return { name: "login" };
  }
  if (authStore.user && !authStore.user.is_admin && to.meta.requiresAdmin) {
    return { name: "home" };
  }
});

export default router;
