<template>
    <div class="row p-1 container-vh-92" style="overflow-y: scroll ">
        <div class="col-xl-10 col-lg-12 row m-auto justify-content-center align-items-center">
            <div class="col-xl-1 col-lg-2 me-3 cropItemChooser">
                <button @click="startMagicWand" title="magic Wand - draw and crop" data-placement="right" data-toggle="tooltip" class="btn border mb-4 cropIcons">
                    <i class="bi bi-magic mythIconLg"></i>
                </button>

                <button @click="startPen" title="Pen - draw and crop" data-placement="right" data-toggle="tooltip" class="btn border mb-4 cropIcons">
                    <i class="bi bi-pencil mythIconLg"></i>
                </button>

                <button data-toggle="tooltip" data-placement="right" title="Rectangle - draw and crop" @click="startRectangle" class="btn border mb-4 cropIcons">
                    <i class="bi bi-fullscreen mythIconLg"></i>
                </button>

                <button data-toggle="tooltip" data-placement="right" title="Circle - draw and crop" @click="startCircle" class="btn border mb-4 cropIcons">
                    <i class="bi bi-dot border-1 mythIconLg"></i>
                </button>
                <button data-toggle="tooltip" data-placement="right" title="Circle - draw and crop" @click="resetDrawing" class="btn border mb-4 cropIcons">
                    <i class="bi-eraser-fill border-1 mythIconLg"></i>
                </button>
                <button data-toggle="tooltip" data-placement="right" title="Circle - draw and crop" @click="zoomUp" class="btn border mb-4 cropIcons">
                    <i class="bi bi-zoom-in border-1 mythIconLg"></i>
                </button>
                <button data-toggle="tooltip" data-placement="right" title="Circle - draw and crop" @click="zoomDown" class="btn border mb-4 cropIcons">
                    <i class="bi bi-zoom-out border-1 mythIconLg"></i>
                </button>
            </div>
            <div class="col-lg-7">
                <div id="upButtonsdiv" class="py-3 d-flex justify-content-center align-items-center"   :style="{ width: this.buttonDivWidth + 'px', maxWidth: '600px' }">
                    <!-- @click="resetDrawing" -->
                    <!-- <button class="btn btn-light ms-4 me-4 border border-dark" data-toggle="tooltip" data-placement="right" title="Clear - reset drawing" data-bs-toggle="modal" data-bs-target="#cropClearModal">
                            <span class="px-4">RESET</span>
                    </button> -->
                    <!-- <button class="btn btn-light ms-4 me-4 border border-dark"  @click="btnApplyClicked()">
                        <span class="px-4">CROP</span>
                    </button> -->
                    <button class="btn btn-dark" data-toggle="tooltip" data-placement="right" title="Apply drawing" data-bs-toggle="modal" data-bs-target="#cropApplyModal">
                        <span class="px-4">Apply</span>
                    </button>
                </div>
                <canvas :height="canvasHeight" :width="canvasWidth" resize id="canvas"></canvas>           
            </div>
            <div class="bottom-0 pt-5">
                <button type="button" class="btn icons ms-auto me-4" data-bs-toggle="modal" title="Help - Information about usage." data-bs-target="#exampleModal">
                    <span class="fs-6 fw-light">Help - Information About Usage <i class="fa-regular fa-circle-question icons fs-6"></i></span>
                </button>
            </div>
            <!-- <div class="modal fade" id="cropApplyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="cropApplyModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-success text-center">
                            <span>CROP İşlemi Başarılı</span>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success" data-bs-dismiss="modal">Continue</button>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="modal fade" id="cropClearModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
                aria-labelledby="cropClearModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle"><strong>Confirmation</strong></h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Are you sure undo changes that you did?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-success" @click="resettoOriginal" data-bs-dismiss="modal">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="cropApplyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
                    aria-labelledby="cropApplyModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle"><strong>Confirmation</strong></h5>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Are you sure you want to apply the changes that you did?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-success" @click="btnContinueClicked" data-bs-dismiss="modal">Apply</button>
                            </div>
                        </div>
                    </div>
            </div>    
        </div>
    </div>
</template>

<script>
import cropper from "../../assets/js/cropper";
export default {
    name  : "CropApp",
    props : ['fileSrc', 'containerTypes'],
    data() {
        return {
            canvas: null,
            cropper: null,
            imageSource: null,
            canvasWidth: 600,
            canvasHeight: 600,
            croppedImageData: null,
            isActive: false,
            origiNalFilesrc: null,
            buttonDivWidth: 512,
        };
    },
    computed: {

    },
    methods : {
        isLoading(_stat){
            this.$emit("ChangeLoaderStatus",_stat);
        },
        closeUsage() {
            this.isActive = !this.isActive;
        },
        zoomDown() {
            this.cropper.zoomOut();
        },
        zoomUp() {
            this.cropper.zoomIn();
        },
        resetDrawing() {
            this.cropper.clear();
            this.basicStart(this.fileSrc);
        },
        resettoOriginal() {
            this.sleep(500)
                .then(() => {
                    this.cropper.clear();
                    this.croppedImageData = this.fileSrc;
                }).then(() => {
                    this.basicStart(this.croppedImageData);
                });
        },
        startPen() {
            this.cropper.startPen();
        },
        startRectangle() {
            this.cropper.startRectangle();
        },
        startCircle() {
            this.cropper.startCircle();
        },
        startMagicWand() {
            this.cropper.startWand();
        },
        triggerGetPos() {
            this.isLoading(true);
            this.getPos();
        },
        snackBar() {
        let toast = document.getElementById("snackbar");
        toast.className = "show";
        setTimeout(function () { toast.className = toast.className.replace("show", ""); }, 10000);
        this.btnApplyClicked();
        },
        getPos() {
            let imgList = this.cropper.getPos();
            let output = [];
            if (imgList) {
                let croppedImage = this.cropper.crop(imgList);
                this.croppedImageData = croppedImage;
               
                this.containerPos = output.join("");
            }
            this.isLoading(false);
        },
        openImage(data) {
            let image = new Image();
            image.src = data;
            let w = window.open("");
            w.document.write(image.outerHTML);
        },
        basicStart(sourceImage) {
            let image = new Image();
            image.src = sourceImage;
            image.onload = function () { };
            // this.canvasWidth = image.naturalWidth;
            // this.canvasHeight = image.naturalHeight;
            const defaultOption = {
                move: true,
                select: true,
                zoom: true,
                fullZoom: true,
                strokeColor: "#39f",
                selectedColor: null,
                fillColor: "rgba(0, 0, 0, 0.1)",
                onDrawEnd: null,
                onSelected: null,
            };
            this.sleep(500)
                .then(() => {
                    this.canvas = this.$el.querySelector("canvas");
                    this.cropper = new cropper(
                        this.canvas,
                        defaultOption,
                        image.naturalWidth,
                        image.naturalHeight,
                    );
                })
                .then(() => {
                    this.cropper.setImage(
                        sourceImage,
                        image.naturalWidth,
                        image.naturalHeight,
                    );
                    this.buttonDivWidth = image.naturalWidth;
                });
        },
        sleep(time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },

        btnContinueClicked() {

            this.triggerGetPos();

            if (this.croppedImageData) {
                this.$emit('CropApplied', this.croppedImageData);
            } else {
                this.$emit('CropApplied', this.fileSrc);
            }
            this.$emit('ChangeContainerType', this.containerTypes.StyleTransfer);
        },
        btnApplyClicked(){ 
            this.triggerGetPos();

            if (this.croppedImageData) { 
                this.basicStart(this.croppedImageData);
            }

        },
    },
    watch: {
        fileSrc() {
            this.basicStart(this.fileSrc);
        },
    },
    created() {
        this.isLoading(true);
        this.basicStart(this.fileSrc);
        this.origiNalFilesrc = this.fileSrc
    },
    mounted() {
        this.isLoading(false);
    },
}
</script>

<style>


@-webkit-keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 30px; opacity: 1;}
}

@keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {top: 30px; opacity: 1;}
    to {top: 0; opacity: 0;}
}

@keyframes fadeout {
    from {top: 30px; opacity: 1;}
    to {top: 0; opacity: 0;}
}
.cropIcons{
    width: 100%;
}
@media only screen and (max-width: 991px) {
    .cropIcons{
        width: 24%;
        margin-right: 1%;
    }
    .cropItemChooser button:hover{
        scale: 1 !important;
        z-index: 5;
    }

}
</style>