<template>
  <div class="container-vh-100 flex">
    <div
      class="warning"
      :style="{
        transform: message ? 'translateY(20px)' : 'translateY(-100px)',
        color: textColor,
      }"
    >
      {{ message }}
    </div>
    <SideBar v-on:subOption="handleSubOption"></SideBar>
    <div class="main-container">
      <div :class="['top-title', { 'fade-in': option !== prevOption }]">
        {{ option }}
      </div>
      <div :class="['title', { 'fade-in': subOption !== prevSubOption }]">
        {{ subOption }}
      </div>
      <UserList v-if="subOption === 'User List'"></UserList>
      <UserRegister v-if="subOption === 'User Register'"></UserRegister>
      <CompaniesApp v-if="subOption === 'Companies'"></CompaniesApp>
      <CompaniesHistoryApp
        v-if="subOption === 'Download Count History'"
      ></CompaniesHistoryApp>
      <CompaniesLimitApp
        v-if="subOption === 'Download Limit History'"
      ></CompaniesLimitApp>
      <LibraryApp
        v-on:message="handleMessage"
        v-if="subOption === 'Library'"
      ></LibraryApp>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/admin/SideBar.vue";
import UserList from "@/views/Admin/Users/UserList.vue";
import UserRegister from "@/views/Admin/Users/UserRegister.vue";
import CompaniesApp from "@/views/Admin/Companies/CompaniesApp.vue";
import CompaniesHistoryApp from "@/views/Admin/Companies/CompaniesHistoryApp.vue";
import CompaniesLimitApp from "@/views/Admin/Companies/CompaniesLimitApp.vue";
import LibraryApp from "@/views/Admin/Library/LibraryApp.vue";

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export default {
  components: {
    SideBar,
    UserList,
    UserRegister,
    CompaniesApp,
    CompaniesHistoryApp,
    CompaniesLimitApp,
    LibraryApp,
  },
  data() {
    return {
      option: "",
      subOption: "",
      prevSubOption: "",
      prevOption: "",
      message: "",
      textColor: "",
    };
  },
  methods: {
    handleSubOption(subOption, option) {
      setTimeout(() => {
        this.prevSubOption = this.subOption;
        this.prevOption = this.option;
      }, 500);
      this.subOption = subOption;
      this.option = option;
    },

    handleMessage(message, textColor) {
      this.message = message;
      this.textColor = textColor;
      sleep(3000).then(() => {
        this.message = "";
      });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Poppins|Oswald");

@keyframes SlideAnimation {
  0% {
    transform: translateY(800px);
  }
  60% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes LeftSlideAnimation {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  60% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes OpacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  display: flex;
  font-family: "Poppins", sans-serif;
}

.main-container {
  width: calc(100vw - 250px);
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
}

.title {
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  height: 10%;
}

.top-title {
  width: 100%;
}

.fade-in {
  animation: OpacityAnimation 0.8s ease;
}

.warning {
  width: 50%;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 40px 0 calc(50% - 40px);
  position: absolute;
  top: 0;
  transform: translateY(-50px);
  background-color: white;
  box-shadow: 0 0 10px 2px #ced4da;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: 0.5s all ease;
  font-size: 14px;
}
</style>
