<template>
    <div class="row p-5">
        <div class="mb-3">
            <h2>Favorites</h2>
        </div>
        <!-- <span v-if="this.viewData.gallery_result.length > 0" style="text-align: right;" data-toggle="tooltip"
                title="Your company's remaining download right.">
                <a>Available Downloads: <strong>{{ getDownloadRight }}</strong></a>
            </span> -->
        <div class="col-6 col-lg-3 mb-4 col-element " :key="item.image_name" v-for="item in this.viewData.gallery_result">
            <div class="position-relative text-white">
                <img class="w-100" :src="item.image_data">
                <div class="img-overlay">
                    <div class="div-absolute-icon-center-center">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <span class="img-absolute-icon-center-center d-flex align-items-center justify-content-center"
                                @click="openModal(item)">
                                <i class="fa fa-search fa-md"></i>
                            </span>
                            <span class="img-absolute-icon-center-center d-flex align-items-center justify-content-center"
                                @click="openDeleteModal(item)">
                                <i class="fa fa-trash fa-md"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="justify-content-center d-flex">
            <button v-if="this.viewData.buttonStatus" id="showMoreButton" class="btn fs-1 d-block justify-content-center"
                @click="showMore"><i id="buttonArrow" class="bi bi-arrow-down-circle-fill"></i></button>
        </div>
        <div class="modal fade" id="mdlSelectedContent" tabindex="-1" aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg bg-white">
                <div class="modal-content bg-white">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-4">
                                <button class="btn-modal-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                                <div class="d-flex justify-content-end mt-4">
                                </div>
                                <div v-if="this.imageRepeatTipi == 'block'">
                                    <div class="d-flex justify-content-between mt-3">
                                        <span>Scale</span>
                                    </div>
                                    <div class="sliderScaleDiv">
                                        <input type="range" min="32" max="1024" step="10" v-model="sizeVall"
                                            class="form-range w-100" id="sliderScale">
                                    </div>
                                </div>
                                <div v-if="this.viewData.selectedDownloadType != this.viewData.downloadSrcTypes.svg">
                                    <div class="d-flex pt-3 resizeDiv">
                                        <div class="d-block">
                                            <label>Width:
                                                <input class="w-100" type="number" name="widthInput"
                                                    v-model="this.viewData.downloadWidth" min="256" max="8190">
                                            </label>
                                        </div>
                                        <div class="d-block ms-1">
                                            <label class="height-label">Height:
                                                <input class="w-100" type="number" name="heightInput"
                                                    v-model="this.viewData.downloadHeight" min="256" max="8190">
                                            </label>
                                        </div>
                                        <div class="d-block ms-1">
                                            <label class="height-label">Size Type:
                                                <select id="downloadSizetype"
                                                    v-model="this.viewData.selectedDownloadSizeType">
                                                    <option :value="this.viewData.downloadSizeTypes.px">{{
                                                        this.viewData.downloadSizeTypes.px }}</option>
                                                    <option :value="this.viewData.downloadSizeTypes.cm">{{
                                                        this.viewData.downloadSizeTypes.cm }}</option>
                                                    <option :value="this.viewData.downloadSizeTypes.inch">{{
                                                        this.viewData.downloadSizeTypes.inch }}</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-flex pt-3 resizeDiv">
                                        <div class="d-block">
                                            <label>DPI:
                                                <input class="w-100" type="number" name="dpiInput"
                                                    v-model="this.viewData.dpiInput" min="100" max="500">
                                            </label>
                                        </div>
                                        <div class="d-block ms-1">
                                            <label>Export Type:
                                                <select id="downloadType" @change="downloadTypeChanged">
                                                    <option :value="this.viewData.downloadSrcTypes.tiff">{{
                                                        this.viewData.downloadSrcTypes.tiff }}</option>
                                                    <option :value="this.viewData.downloadSrcTypes.svg">{{
                                                        this.viewData.downloadSrcTypes.svg }}</option>
                                                    <option :value="this.viewData.downloadSrcTypes.jpeg">{{
                                                        this.viewData.downloadSrcTypes.jpeg }}</option>
                                                    <option :value="this.viewData.downloadSrcTypes.png">{{
                                                        this.viewData.downloadSrcTypes.png }}</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.viewData.selectedDownloadType == this.viewData.downloadSrcTypes.svg">
                                    <div class="d-flex pt-3 resizeDiv" style="min-height: 76px;">
                                        <div class="d-block"></div>
                                        <div class="d-block ms-1"></div>
                                        <div class="d-block ms-1"></div>
                                    </div>
                                    <div class="d-flex pt-3 resizeDiv">
                                        <div class="d-block">
                                            <label>Color Count:
                                                <input class="w-100" type="number" name="ColorCountInput"
                                                    v-model="this.viewData.colorCount" min="1">
                                            </label>
                                        </div>
                                        <div class="d-block ms-1" style="min-width: 120px;">
                                            <label>Export Type:
                                                <select id="downloadType" @change="downloadTypeChanged">
                                                    <option :value="this.viewData.downloadSrcTypes.svg">{{
                                                        this.viewData.downloadSrcTypes.svg }}</option>
                                                    <option :value="this.viewData.downloadSrcTypes.tiff">{{
                                                        this.viewData.downloadSrcTypes.tiff }}</option>
                                                    <option :value="this.viewData.downloadSrcTypes.jpeg">{{
                                                        this.viewData.downloadSrcTypes.jpeg }}</option>
                                                    <option :value="this.viewData.downloadSrcTypes.png">{{
                                                        this.viewData.downloadSrcTypes.png }}</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5" style="text-align: right;">
                                    <button class="w-100 btn border mt-2 btn-dark" @click="certModal.show()">Download
                                        Image</button>
                                    <button class="w-100 btn border mt-2 btn-dark" @click="btnPatternUseClicked">Use
                                        Pattern</button>
                                    <img src="@/assets/img/certificate-7.svg" width="64" height="64"
                                        v-if="this.viewData.selectedImage" v-show="this.viewData.selectedImage.certificate"
                                        class="certificate-icon mt-2" @click="downloadCertification" :style="{
                                            opacity: !this.viewData.selectedImage.certificate ? 0.3 : 1,
                                            pointerEvents: !this.viewData.selectedImage.certificate ? 'none' : 'auto',
                                            cursor: !this.viewData.selectedImage.certificate ? 'none' : 'pointer',
                                        }" />
                                </div>
                                <div v-if="imageTypeAlertMessage">
                                    <p style="color: red;">Please select the image type</p>
                                </div>
                            </div>
                            <div class="col-8">
                                <div :id="'img-canvasdiv'"
                                    :style="`background-image: url(${this.imageForShow}); background-repeat: repeat; background-size: ${sizeVall}px; width: 512px; height: 512px;`">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle"><strong>Confirmation</strong></h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        You will completely lose this pattern. Do you still want to delete it?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteItem"
                            data-bs-dismiss="modal">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="certificateNameModal" tabindex="-1" role="dialog"
            aria-labelledby="certificateNameModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="certificateNameModalLabel">
                            Certificate Name
                        </h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="form-group">
                                <label for="certificate-name" class="col-form-label">Certificate Name:</label>
                                <input type="text" class="form-control" id="certificate-name" v-model="certificateName" />
                            </div>
                            <span class="form-error-message" v-if="handleErrorMessages">{{
                                certificateNameErrorMessage
                            }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Close
                        </button>
                        <button type="button" class="btn btn-primary" @click="btnDownloadImageClicked">
                            Generate with this name
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import * as mdb from "bootstrap";
import { mapActions } from 'pinia';
import { useDownloadRightStore } from "@/store/modules/downloadRight"
import { useUserCompanyStore } from '@/store/modules/userCompany';
import { useUserCertificateStore } from "@/store/modules/userCertificate";
import { montserratBold } from '@/assets/fonts/montserratBold';
import { montserratRegular } from '@/assets/fonts/montserratRegular';
import { montserratBoldItalic } from '@/assets/fonts/montserratBoldItalic';
import { carattereRegular } from '@/assets/fonts/carattereRegular';

export default {
    name: "UserFavorites",
    props: ['data'],
    data() {
        return {
            viewData: this.data,
            modal: null,
            deleteModal: null,
            sizeVall: 512,
            imageForShow: null,
            imageTypeAlertMessage: false,
            patternToDelete: null,
            companyName: "",
            imageRepeatTipi: null,
            certificateName: "",
            certificateNameErrorMessage: "",
        }
    },
    computed: {
        getDownloadRight() {
            const downloadRightStore = useDownloadRightStore();
            return downloadRightStore.downloadRight
        }
    },
    methods: {
        ...mapActions(useDownloadRightStore, ['fetchDownloadRight']),
        ...mapActions(useUserCompanyStore, ['fetchUserCompanyData']),
        ...mapActions(useUserCertificateStore, ["insertCertificateRequest"]),

        openDeleteModal(item) {
            this.patternToDelete = item;
            this.deleteModal.show();
        },
        deleteItem() {
            this.$emit('deleteGalleryItem', this.patternToDelete.image_id);
            this.viewData.gallery_result.splice(this.viewData.gallery_result.findIndex(image => image.image_id === this.patternToDelete.image_id), 1);
        },
        btnPatternUseClicked() {
            this.modal.hide();
            this.$emit('favoriteUsePatternClicked', this.viewData.selectedImage);
        },
        openModal(_image) {
            this.imageTypeAlertMessage = false;
            this.viewData.selectedImage = _image;
            this.imageForShow = this.viewData.selectedImage.image_data;
            this.imageRepeatTipi = _image.repeat_mode;
            this.sizeVall = 512;
            this.modal.show();
        },
        showMore() {
            let button = document.querySelector('#buttonArrow');

            button.classList.remove('bi-arrow-down-circle-fill');
            button.classList.add('bi-arrow-up-circle-fill');

            window.scrollTo(0, document.body.scrollHeight);
            this.$emit('ShowMore');

        },
        imgSelectClicked(_image) {
            this.$emit('SetImage', _image);
        },
        downloadTypeChanged() {
            this.viewData.selectedDownloadType = document.querySelector('#downloadType').value;
        },
        resetValues() {
            this.sizeVall = 512;
            this.viewData.downloadHeight = 1024;
            this.viewData.downloadWidth = 1024;
            this.viewData.dpiInput = 300;
        },
        btnDownloadImageClicked() {
            if (this.certificateName.trim().length === 0) {
                this.certificateNameErrorMessage = "Certificate name cannot be empty!";
            } else if (this.certificateName.trim().length < 3) {
                this.certificateNameErrorMessage =
                    "The certificate name must consist of at least 4 characters!";
            } else {
                if (this.companyName === "") {
                    this.fetchUserCompanyData().then((response) => {
                        this.companyName = response.data[0].company_name;
                    });
                }
                this.viewData.repeat_count = Math.round(512 / this.sizeVall).toString();
                var width, height, downloadConfirmed;
                if (this.viewData.selectedDownloadSizeType == "cm") {
                    width = (this.viewData.dpiInput * this.viewData.downloadWidth) / 2.54;
                    height =(this.viewData.dpiInput * this.viewData.downloadHeight) / 2.54;
                } else if (this.viewData.selectedDownloadSizeType == "inch") {
                    width = this.viewData.dpiInput * this.viewData.downloadWidth;
                    height = this.viewData.dpiInput * this.viewData.downloadHeight;
                } else {
                    width = this.viewData.downloadWidth;
                    height = this.viewData.downloadHeight;
                }
                if (width > 8192 || height > 8192) {
                    if (this.viewData.selectedDownloadSizeType == "cm") {
                        const maxwidth = (8192 * 2.54) / this.viewData.dpiInput;
                        const maxheight = (8192 * 2.54) / this.viewData.dpiInput;
                        downloadConfirmed = confirm(`The wanted size (${this.viewData.downloadWidth
                            }${this.viewData.selectedDownloadSizeType} * ${this.viewData.downloadHeight
                            }${this.viewData.selectedDownloadSizeType}) is too large. 
                    \nThe maximum size allowed with DPI (${this.viewData.dpiInput
                            }) is (${Math.round(maxwidth)}${this.viewData.selectedDownloadSizeType
                            } * ${Math.round(maxheight)}${this.viewData.selectedDownloadSizeType
                            }).
                    \nDo you want to download with the maximum allowed size?`);
                    } else if (this.viewData.selectedDownloadSizeType == "inch") {
                        const maxwidth = 8192 / this.viewData.dpiInput;
                        const maxheight = 8192 / this.viewData.dpiInput;
                        downloadConfirmed = confirm(`The wanted size (${this.viewData.downloadWidth
                            }${this.viewData.selectedDownloadSizeType} * ${this.viewData.downloadHeight
                            }${this.viewData.selectedDownloadSizeType}) is too large. 
                    \nThe maximum size allowed with DPI (${this.viewData.dpiInput
                            }) is (${Math.round(maxwidth)}${this.viewData.selectedDownloadSizeType
                            } * ${Math.round(maxheight)}${this.viewData.selectedDownloadSizeType
                            }).
                    \nDo you want to download with the maximum allowed size?`);
                    } else {
                        downloadConfirmed =
                            confirm(`The wanted size (${this.viewData.downloadWidth}${this.viewData.selectedDownloadSizeType} * ${this.viewData.downloadHeight}${this.viewData.selectedDownloadSizeType}) is too large. 
                     \nThe maximum size allowed is (8192${this.viewData.selectedDownloadSizeType} * 8192${this.viewData.selectedDownloadSizeType}).
                     \nDo you want to download with the maximum allowed size?`);
                    }
                    if (downloadConfirmed) {
                        const updatedViewData = { ...this.viewData };
                        width = 8192;
                        height = 8192;
                        updatedViewData.dpiInput = 300;
                        updatedViewData.selectedDownloadSizeType =
                            this.viewData.downloadSizeTypes.px;
                        updatedViewData.downloadWidth = Math.round(width);
                        updatedViewData.downloadHeight = Math.round(height);

                        this.$emit("userFavoritesDownloadImage", this.viewData, updatedViewData);
                        this.certModal.hide();
                        this.generatePdf();
                    }
                } else {
                    const updatedViewData = { ...this.viewData };
                    if (updatedViewData.dpiInput > 360) {
                        updatedViewData.dpiInput = 360;
                    }
                    updatedViewData.selectedDownloadSizeType =
                        this.viewData.downloadSizeTypes.px;
                    updatedViewData.downloadWidth = Math.round(width);
                    updatedViewData.downloadHeight = Math.round(height);
                    this.$emit("userFavoritesDownloadImage", this.viewData, updatedViewData);
                    this.certModal.hide();
                    this.generatePdf();
                }
            }
        },
        downloadCertification() {
            let selectedImage = this.viewData.selectedImage
            let pdfName = selectedImage.image_name.slice(0, -4)
            var pdf = new jsPDF('l', 'pt');
            pdf.addFileToVFS('@/assets/fonts/Montserrat-Regular.tff', montserratRegular)
            pdf.addFileToVFS('@/assets/fonts/Montserrat-Bold.tff', montserratBold)
            pdf.addFileToVFS('@/assets/fonts/Montserrat-BoldItalic.tff', montserratBoldItalic)
            pdf.addFileToVFS('@/assets/fonts/Carattere-Regular.tff', carattereRegular)
            pdf.addFont('@/assets/fonts/Montserrat-Regular.tff', 'montserratR', 'normal')
            pdf.addFont('@/assets/fonts/Montserrat-Bold.tff', 'montserratB', 'normal')
            pdf.addFont('@/assets/fonts/Montserrat-BoldItalic.tff', 'montserratBI', 'normal')
            pdf.addFont('@/assets/fonts/Carattere-Regular.tff', 'carattereR', 'normal')

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            let date = new Date().toLocaleDateString("en-TR");
            let time = new Date().toLocaleTimeString("en-TR");

            var mythLogo = new Image();
            var backGroundImage = new Image();
            mythLogo.src = require("@/assets/img/logo.png")
            backGroundImage.src = require("@/assets/img/pdf-background.png")
            backGroundImage.onload = () => {
                pdf.addImage(backGroundImage, 'png', 0, 0, width, height);
                pdf.addImage(mythLogo, 'png', (width / 2) - 25, 75, 50, 50);

                pdf.setFont("montserratR", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(24);
                pdf.text('CERTIFICATE OF OWNERSHIP', width / 2, 175, { align: 'center' });

                pdf.setFont("montserratR", "normal");
                pdf.setFontSize(12);
                pdf.text('THIS CERTIFICATE BELONGS TO:', width / 2, 215, { align: 'center' });

                pdf.setFont("carattereR", "normal");
                pdf.setTextColor(254, 99, 99);
                pdf.setFontSize(28);
                pdf.text(this.companyName, width / 2, 245, { align: 'center' });

                pdf.addImage(selectedImage.image_data, 'png', (width / 2) - 50, 270, 100, 100);

                pdf.setFont("montserratR", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(11);
                pdf.text(`This certificate of ownership hereby assigns all intellectual property rights related to the pattern mentioned above to the Pattern Owner indefinitely and without limitations, as of ${date}-${time}.`, width / 2, 400, { maxWidth: width * 0.60, lineHeightFactor: 1.5, align: 'center' });

                pdf.setFont("montserratR", "normal");
                pdf.setTextColor(254, 99, 99);
                pdf.setFontSize(10);
                pdf.text('On behalf of MYTH Yapay Zekâ ve Tekstil Anonim Şirketi', width / 2, 475, { align: 'center' });
                pdf.setTextColor(0, 0, 0);
                pdf.text('Özgecan Üstgül', width / 2, 490, { align: 'center' });
                pdf.save(pdfName + '.pdf');
            };
        },
        generatePdf() {
            this.createPdfContent().then((pdf) => {
                var blobPdf = pdf.output("blob");
                var reader = new FileReader();
                reader.readAsDataURL(blobPdf);
                reader.onloadend = () => {
                    var base64data = reader.result.split(",")[1];
                    this.insertCertificateRequest({
                        certificate_name: this.certificateName.trim(),
                        certificate_data: base64data,
                    }).then(() => { });
                };
            });
        },
        createPdfContent() {
            return new Promise((resolve) => {
                let selectedImage = this.viewData.selectedImage;
                // let pdfName = selectedImage.image_filename.slice(0, -3)
                var pdf = new jsPDF("l", "pt", "", true);

                pdf.addFileToVFS(
                    "@/assets/fonts/Montserrat-Regular.tff",
                    montserratRegular
                );
                pdf.addFileToVFS("@/assets/fonts/Montserrat-Bold.tff", montserratBold);
                pdf.addFileToVFS(
                    "@/assets/fonts/Montserrat-BoldItalic.tff",
                    montserratBoldItalic
                );
                pdf.addFileToVFS(
                    "@/assets/fonts/Carattere-Regular.tff",
                    carattereRegular
                );
                pdf.addFont(
                    "@/assets/fonts/Montserrat-Regular.tff",
                    "montserratR",
                    "normal"
                );
                pdf.addFont(
                    "@/assets/fonts/Montserrat-Bold.tff",
                    "montserratB",
                    "normal"
                );
                pdf.addFont(
                    "@/assets/fonts/Montserrat-BoldItalic.tff",
                    "montserratBI",
                    "normal"
                );
                pdf.addFont(
                    "@/assets/fonts/Carattere-Regular.tff",
                    "carattereR",
                    "normal"
                );

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                let date = new Date().toLocaleDateString("en-TR");
                let time = new Date().toLocaleTimeString("en-TR");

                var mythLogo = new Image();
                var backGroundImage = new Image();
                mythLogo.src = require("@/assets/img/logo.png");
                backGroundImage.src = require("@/assets/img/pdf-background.png");
                backGroundImage.onload = () => {
                    pdf.addImage(
                        backGroundImage,
                        "png",
                        0,
                        0,
                        width,
                        height,
                        undefined,
                        "FAST"
                    );
                    pdf.addImage(
                        mythLogo,
                        "png",
                        width / 2 - 25,
                        75,
                        50,
                        50,
                        undefined,
                        "FAST"
                    );

                    pdf.setFont("montserratR", "normal");
                    pdf.setTextColor(0, 0, 0);
                    pdf.setFontSize(24);
                    pdf.text("CERTIFICATE OF OWNERSHIP", width / 2, 175, {
                        align: "center",
                    });

                    pdf.setFont("montserratR", "normal");
                    pdf.setFontSize(12);
                    pdf.text("THIS CERTIFICATE BELONGS TO:", width / 2, 215, {
                        align: "center",
                    });

                    pdf.setFont("carattereR", "normal");
                    pdf.setTextColor(254, 99, 99);
                    pdf.setFontSize(28);
                    pdf.text(this.companyName, width / 2, 245, { align: "center" });

                    pdf.addImage(
                        selectedImage.image_data,
                        "png",
                        width / 2 - 50,
                        270,
                        100,
                        100,
                        undefined,
                        "FAST"
                    );

                    pdf.setFont("montserratR", "normal");
                    pdf.setTextColor(0, 0, 0);
                    pdf.setFontSize(11);
                    pdf.text(
                        `This certificate of ownership hereby assigns all intellectual property rights related to the pattern mentioned above to the Pattern Owner indefinitely and without limitations, as of ${date}-${time}.`,
                        width / 2,
                        400,
                        { maxWidth: width * 0.6, lineHeightFactor: 1.5, align: "center" }
                    );

                    pdf.setFont("montserratR", "normal");
                    pdf.setTextColor(254, 99, 99);
                    pdf.setFontSize(10);
                    pdf.text(
                        "On behalf of MYTH Yapay Zekâ ve Tekstil Anonim Şirketi",
                        width / 2,
                        475,
                        { align: "center" }
                    );
                    pdf.setTextColor(0, 0, 0);
                    pdf.text("Özgecan Üstgül", width / 2, 490, { align: "center" });
                    resolve(pdf);
                };
            });
        },
        handleErrorMessages() {
            if (this.certificateNameErrorMessage.length !== 0) {
                return true;
            }
        },
    },
    created() {
        if (this.viewData.gallery_result.length === 0) {
            this.$emit('UserFavoritesLoadData');
        }
    },
    mounted() {
        let modal = document.querySelector('#mdlSelectedContent');
        let certModal = document.querySelector("#certificateNameModal");
        this.certModal = new mdb.Modal(certModal);
        this.modal = new mdb.Modal(modal);

        let deleteModal = document.querySelector('#deleteModal');
        this.deleteModal = new mdb.Modal(deleteModal);

    },
}
</script>

<style>
.btn-container {
    text-align: center;
    margin-top: 5;
}

.resizeDiv input {
    height: 36px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
}

.btn-use-pattern {
    color: white;
    background-color: #c2c2c2;
}

.btn-use-pattern:hover {
    color: black;
    background-color: gray;
}

.select-area {
    height: 36px;
    margin-right: 0px;
}

#downloadSizetype {
    height: 36px;
}

#downloadType {
    height: 36px;
}

.modal-content {
    width: 800px;
}

.certificate-icon:hover {
    width: 66px;
    height: 66px;
}
</style>