import axios from "axios";
import { defineStore } from "pinia";

export const useCompanyStore = defineStore("company", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async fetchCompanyDataRequest(payload) {
      try {
        const url = "/admin/company_management/companies";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error fetching company data: ", error);
        throw error;
      }
    },
    async createCompanyRequest(payload) {
      try {
        const url = "/admin/company_management/create";
        const response = await axios.post(url, payload);
          return response;
        } catch (error) {
          console.error("Error creating new company: ", error);
          throw error;
        }
      },
      async updateCompanyRequest(payload) {
        try {
          const url = "/admin/company_management/update";
          const response = await axios.patch(url, payload);
          return response;
        } catch (error) {
          console.error("Error updating company: ", error);
          throw error;
        }
      },
      async deleteCompanyRequest(companyId) {
        try {
          const url = `/admin/company_management/delete-company/${companyId}`;
          const response = await axios.delete(url)
        return response;
      } catch (error) {
        console.error("Error deleting company: ", error);
        throw error;
      }
    },
    async fetchCompanyDownloadData(payload) {
      try {
        const url = "/admin/company_management/get-credit-history";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error fecthing company download history data:", error);
        throw error;
      }
    },
    async fetchCompanyLimitationData(payload) {
      try {
        const url = "admin/company_management/get-limitation-history";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error fecthing company limitation history data:", error);
        throw error;
      }
    },
  },
});
