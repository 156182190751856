import axios from "axios";
import { defineStore } from "pinia";
import { setToken } from "@/axios";
import User from "@/classes/user";

export const useAuthenticationStore = defineStore("authentication", {
  state: () => {
    return {
      token: "",
      status: false,
      user: null,
      username: "",
    };
  },
  getters: {},
  actions: {
    async login(credentials) {
      try {
        const url = "/user/login";
        const response = await axios.post(url, credentials);
        this.user = response.data.data.user_info;
        this.username = this.user.username
        this.token = response.data.data.myth_token;
        setToken(response.data.data.myth_token);
        localStorage.setItem("token", this.token);
        this.status = true;
        return response;
      } catch (error) {
        console.log("Error while login: ", error);
        throw error;
      }
    },
    async authStatus() {
      try {
        const url = "/user/login_status";
        const response = await axios.get(url);
        this.status = response.data.status;
        return response;
      } catch (error) {
        setToken("");
        this.user = null;
        this.status = false;
        localStorage.removeItem("token");
        throw error;
      }
    },
    async initFetch() {
      try {
        const storedToken = localStorage.getItem("token") || "";
        if (storedToken) {
          this.token = storedToken;
          setToken(storedToken);
          await this.fetchUser();
        }
      } catch (error) {
        return true;
      }
    },
    async fetchUser() {
      try {
        const response = await axios.get("/user");
        this.user = new User(response.data[0]);
        this.username = this.user.username
        this.status = true;
        localStorage.setItem("token", this.token);
      } catch (error) {
        this.user = null;
        this.username = "";
        this.status = false;
      }
      
    },
  },
});
