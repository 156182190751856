<template>
  <div class="navbar">
    <div class="navbar-title">{{ title }}</div>
    <div class="button-container">
      <div
        v-for="menuItem in menuData"
        :key="menuItem.option"
        class="option-container"
      >
        <button
          @click="handleClick(menuItem.option)"
          @mouseenter="handleHover(menuItem.option)"
          @mouseleave="handleHover('')"
          class="option"
        >
          <div
            class="icon-container"
            :style="{
              paddingLeft:
                isHovered === menuItem.option || isClicked === menuItem.option
                  ? '10px'
                  : '0',
            }"
          >
            <i
              v-if="isClicked === menuItem.option"
              class="bi bi-caret-down-fill"
            ></i>
            <i v-else class="bi bi-caret-right-fill"></i>
          </div>
          {{ menuItem.option }}
          <div
            class="option-hovered"
            :style="{
              width: isClicked === menuItem.option ? '100%' : '0',
              opacity: isClicked === menuItem.option ? '1' : '0',
            }"
          ></div>
        </button>
        <div
          class="sub-option-container"
          :style="{
            height:
              isClicked === menuItem.option
                ? menuItem.subOptions.length * 40 + 'px'
                : '0',
          }"
        >
          <button
            v-for="subOptionItem in menuItem.subOptions"
            :key="subOptionItem.name"
            class="sub-option-button"
            @click="handleSubOption(subOptionItem.name, menuItem.option)"
            @mouseenter="handleHover(subOptionItem.name)"
            @mouseleave="handleHover('')"
            :style="{
              color: subOption === subOptionItem.name ? 'black' : '#6c757d',
              paddingLeft: subOption === subOptionItem.name ? '50px' : '20px',
              backgroundColor: getSubOptionBackgroundColor(subOptionItem.name),
            }"
          >
            {{ subOptionItem.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuData from "@/views/Admin/menu.json";

export default {
  data() {
    return {
      title: "Myth AI Admin Panel",
      isClicked: "",
      isHovered: "",
      subOption: "",
      menuData: menuData,
    };
  },
  methods: {
    handleClick(option) {
      if (this.isClicked === option) {
        this.isClicked = "";
      } else {
        this.isClicked = option;
      }
    },

    handleHover(hover) {
      this.isHovered = hover;
    },

    handleSubOption(subOption, option) {
      this.subOption = subOption;
      this.isClicked = option;
      this.$emit("subOption", this.subOption, this.isClicked);
    },

    getSubOptionBackgroundColor(subOptionName) {
      if (this.subOption === subOptionName) {
        return "#ced4da";
      } else if (this.isHovered === subOptionName) {
        return "#e9ecef";
      } else {
        return "white";
      }
    },
  },
};
</script>

<style>
.navbar {
  width: 250px;
  height: 100vh;
  display: flex;
  padding: 0;
  background-color: white;
  box-shadow: 0 0 5px 1px lightgray;
}

.navbar-title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 50px;
  font-size: 20px;
  margin-left: 20px;
  font-weight: bold;
}

.button-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  position: absolute;
  top: 30%;
  left: 0;
}

.option-container {
  width: auto;
  height: auto;
  position: relative;
}

.option {
  width: 100%;
  height: 60px;
  background-color: transparent;
  font-size: 14px;
  transition: all 0.2s;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  border: none;
  padding: 0;
}

.option-hovered {
  width: 0;
  height: 3px;
  position: absolute;
  bottom: 0;
  background-color: black;
  transition: all 0.2s;
  opacity: 0;
}

.option-hovered:hover {
  width: 100%;
  opacity: 1;
}

.sub-option-container {
  width: 250px;
  height: 0;
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  transition: all 0.2s;
  padding: 0;
  overflow: hidden;
}

.sub-option-wrapper:last-child {
  border-bottom: none;
}

.sub-option-button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border: none;
  background-color: white;
  font-size: 14px;
  color: #6c757d;
  padding-left: 20px;
  transition: all 0.2s;
  cursor: pointer;
}

.icon-container {
  height: auto;
  width: 40px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-left: 0;
  justify-content: center;
  transition: 0.2s all;
}
</style>
