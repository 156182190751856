import axios from "axios";
import { defineStore } from "pinia";

export const useStyleTransferStore = defineStore("styleTransfer", {
  state: () => {
    return {
    };
  },
  getters: {},
  actions: {
    async downloadStyledImage(payload) {
      try {
        const url = "/design_process/style/get-styled-image";
        const response = await axios.post(url, payload, {
            headers: { contentType: "image/png" },
            responseType: "blob",
          })
        return response;
      } catch (error) {
        console.error("Error downloading styled image:", error);
        throw error;
      }
    },
  },
});
