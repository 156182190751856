import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return { };
  },
  getters: {
  },
  actions: {
    async fetchUsersRequest() {
      try {
        const url = "/admin/user_management/users";
        const response = await axios.get(url).then();
        return response;
      } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
      }
    },
    async registerUser(payload) {
      try {
        const url = "/admin/user_management/register";
        const response = await axios.post(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error fetching company data:", error);
        throw error;
      }
    },
    async updateUserRequest(payload) {
      try {
        const url = "/admin/user_management/update-user";
        const response = await axios.patch(url, payload).then();
        return response;
      } catch (error) {
        console.error("Error updating user:", error);
        throw error;
      }
    },
    async deleteUserRequest(user_id) {
      try {
        const url = `/admin/user_management/delete-user/${user_id}`;
        const response = await axios.delete(url).then();
        return response;
      } catch (error) {
        console.error("Error fetching company data:", error);
        throw error;
      }
    },
  },
});