import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export const setToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = "Bearer " + token;
  } else {
    axios.defaults.headers.common.Authorization = false;
  }
};
