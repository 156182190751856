<template>
    <div class="container-users">
        <trinity-rings-spinner v-if="spinnerStatus" class="spinner" :animation-duration="2000" :size="90" color="#000000" />
        <div class="label-row">
            <div class="label">First Name</div>
            <div class="label">Last Name</div>
            <div class="label">Username</div>
            <div class="label">Password</div>
            <div class="label">Email</div>
            <div class="label">Company</div>
            <div class="label">Downloads</div>
        </div>
        <div v-for="(user, idx) in userList" class="user-row" :key="idx" :style="{
            boxShadow: isEdited === idx ? '0 0 20px 2px lightgray' : 'none',
            filter: isEdited === idx || isEdited === null ? 'none' : 'blur(10px)',
            pointerEvents: isEdited === idx || isEdited === null ? 'auto' : 'none',
        }">
            <input class="value" v-model="user.first_name" :disabled="isEdited !== idx"
                :style="{ border: isEdited === idx ? '1px solid lightgray' : 'none' }" type="text" />
            <input class="value" v-model="user.last_name" :disabled="isEdited !== idx"
                :style="{ border: isEdited === idx ? '1px solid lightgray' : 'none' }" type="text" />
            <input class="value" v-model="user.username" :disabled="isEdited !== idx"
                :style="{ border: isEdited === idx ? '1px solid lightgray' : 'none' }" type="text" />
            <input class="value" v-model="user.password" :disabled="isEdited !== idx" @change="passwordChangeStatus = true;"
                :style="{ border: isEdited === idx ? '1px solid lightgray' : 'none' }" type="text" />
            <input class="value" v-model="user.email" :disabled="isEdited !== idx"
                :style="{ border: isEdited === idx ? '1px solid lightgray' : 'none' }" type="text" />
            <input class="value" :value="user.company_name" :disabled="true" type="text" />
            <input class="value" :value="user.downloads" :disabled="true" type="number" />

            <button class="action-button" :style="{ right: '70px', color: 'black' }" @click="
                handleEdit(idx);">
                <i v-if="isEdited !== idx" class="bi bi-pen"></i>
                <i v-else :style="{ color: 'green', transform: 'scale(1.2)' }" class="bi bi-check-lg"
                    @click="updateUser(user.user_id)"></i>
            </button>
            <button class="action-button" :style="{ right: '40px', color: 'red' }">
                <i class="bi bi-trash3" @click="deleteUser(user.user_id)"></i>
            </button>
            <button v-if="!(isEdited !== idx)" class="action-button" :style="{ right: '10px', color: 'black' }"
                @click="isEdited = null;">
                <i class="bi bi-x"></i>
            </button>
        </div>
    </div>
    <toast-view ref="toast"></toast-view>
    <!-- <div class="container-users">
        <trinity-rings-spinner v-if="spinnerStatus" class="spinner" :animation-duration="2000" :size="90" color="#000000" />
        <table>
            <thead>
                <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Username</th>
                    <th scope="col">Password</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company</th>
                    <th scope="col">Downloads</th>
                    <th scope="col" class="text-center">Edit</th>
                    <th scope="col" class="text-center">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in userList" :key="index">
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.last_name }}</td>
                    <td>{{ user.username.slice(0, 10) }}</td>
                    <td>{{ user.password.slice(0, 7) }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.company_name }}</td>
                    <td>{{ user.downloads }}</td>
                    <td class="text-center"><i v-if="isEdited !== index" class="bi bi-pen"
                            :style="{ color: 'green', transform: 'scale(1.2)', cursor: 'pointer' }"
                            @click="editUserData(user)"></i></td>
                    <td class="text-center"><i class="bi bi-trash cursor-pointer"
                            :style="{ color: 'red', transform: 'scale(1.2)', cursor: 'pointer' }"
                            @click="deleteUserData(user.user_id)"></i></td>
                </tr>
            </tbody>
        </table>
        <toast-view ref="toast"></toast-view>
    </div> -->
</template>

<script>
// import * as mdb from "bootstrap";
import { mapActions } from "pinia";
import { useUserStore } from "@/store/modules/admin/user";
import { TrinityRingsSpinner } from "epic-spinners";
import ToastView from "@/components/admin/ToastView.vue";

export default {
    components: {
        TrinityRingsSpinner, ToastView
    },
    data() {
        return {
            isEdited: null,
            userList: [],
            spinnerStatus: true,
            passwordChangeStatus: false,
        };
    },
    methods: {
        ...mapActions(useUserStore, ["fetchUsersRequest", "updateUserRequest", "deleteUserRequest"]),

        handleEdit(id) {
            if (this.isEdited !== null) {
                this.isEdited = null;
            } else {
                this.isEdited = id;
            }
        },
        async getUsers() {
            await this.fetchUsersRequest().then((response) => {
                this.userList = response.data.data.user_list;
                this.spinnerStatus = false;
            }).catch((error) => {
                this.spinnerStatus = false;
                this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
            })
        },
        async updateUser(userId) {
            this.spinnerStatus = true;
            let selectedUser = this.userList.filter(user => user.user_id === userId)[0];
            let payload;
            if (this.passwordChangeStatus) {
                console.log('değişiklik SAPTANDI!!!!!')
                payload = {
                    "user_id": +userId,
                    "first_name": selectedUser.first_name,
                    "last_name": selectedUser.last_name,
                    "username": selectedUser.username,
                    "password": selectedUser.password,
                    "email": selectedUser.email,
                }
                this.passwordChangeStatus = false;
            } else {
                console.log('değişiklik SAPTANMADI!!!!!')
                payload = {
                    "user_id": +userId,
                    "first_name": selectedUser.first_name,
                    "last_name": selectedUser.last_name,
                    "username": selectedUser.username,
                    "email": selectedUser.email,
                }
            }
            await this.updateUserRequest(payload).then(() => {
                this.getUsers();
                this.$refs.toast.triggerToast(`${selectedUser.username} has been successfully updated!`, "top-center", "success");
            }).catch((error) => {
                this.spinnerStatus = false;
                this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
            })
        },
        async deleteUser(userId) {
            this.spinnerStatus = true;
            let userName = this.userList.filter(user => user.user_id === userId)[0].username;
            await this.deleteUserRequest(userId).then(() => {
                this.getUsers();
                this.$refs.toast.triggerToast(`${userName} has been successfully deleted!`, "top-center", "success");
            }).catch((error) => {
                this.spinnerStatus = false;
                this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
            })
        },
    },
    mounted() {
        this.getUsers();
    }
}
</script>

<style scoped>
.container-users {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 2px #ced4da;
    animation: SlideAnimation 0.8s ease;
    overflow: auto;
}

.label-row {
    width: 100%;
    height: 50px;
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    padding: 0 60px 0 30px;
    box-sizing: border-box;
}

.label {
    width: 12%;
    font-size: 12px;
    color: black;
    margin-right: 2.28%;
}

.user-row {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ced4da;
    display: flex;
    align-items: center;
    padding: 0 60px 0 30px;
    box-sizing: border-box;
    position: relative;
    transition: 0.2s all;
}

.value {
    border: none;
    width: 12% !important;
    font-size: 12px;
    height: 30px !important;
    outline: none !important;
    background-color: white;
    margin-right: 2.18% !important;
    padding: 5px !important;
}

.action-button {
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.2s;
}

.action-button:hover {
    box-shadow: 0 0 10px 2px #ced4da;
}

.input-container {
    width: 1100px;
    height: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px 2px #ced4da;
    background-color: white;
    position: absolute;
    z-index: 9;
    animation: SlideUpdateAnimation 0.8s ease;
}

#divHeader {
    padding: 10px;
    cursor: move;
    z-index: 10;
}

.register-input {
    font-size: 12px;
    width: 140px;
    border-radius: 5px;
    border: 0.01px solid grey;
}

.submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    width: 60px;
    height: 60%;
    color: white;
    background-color: black;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
}

.close-button {
    margin-right: 10px;
}

.close-button:hover {
    cursor: pointer;
}

.container-users {
    overflow: auto;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 2px #ced4da;
    animation: SlideAnimation 0.8s ease;
    position: relative;
}

.spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

th {
    padding: 10px;
    background-color: #e9ecef;
    width: 5%;
    font-size: 12px;
    color: black;
}

td {
    padding: 10px;
    width: 5%;
    font-size: 12px;
}

@keyframes SlideUpdateAnimation {
    0% {
        transform: translateX(1800px);
    }

    60% {
        transform: translateX(-20px);
    }

    100% {
        transform: translateX(0);
    }
}
</style>