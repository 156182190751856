import axios from "axios";
import { defineStore } from "pinia";

export const useUserCompanyStore = defineStore("userCompany", {
  state: () => {
    return {};
  },
  getters: {},
  actions: {
    async fetchUserCompanyData() {
      try {
        const url = "/user/user-company";

        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error("Error fetching tdpl data:", error);
        throw error;
      }
    },
  },
});
