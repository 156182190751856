<template>
  <button class="add-company-button" @click="handleClose">
    <i class="bi bi-plus-lg"></i> Add Company
  </button>
  <div class="add-company-container" :style="{
    width: isClosed ? '1000px' : '0',
    paddingRight: isClosed ? '5px' : '0',
  }">
    <input class="new-value" placeholder="New Company Name" v-model="newCompanyName" type="text" />
    <input class="new-value" placeholder="Download Count" v-model="newDownloadCount" type="number" min="0" />
    <input class="new-value" placeholder="Download Limit" v-model="newDownloadLimit" type="number" min="0" />
    <div class="checkbox-div">
      <label for="limitationStatus">Limitation Status</label>
      <input v-model="newLimitationStatus" type="checkbox" id="limitationStatus" />
    </div>
    <input class="new-value" placeholder="Limitation Count" v-model="newLimitationCount" type="number" min="0" />
    <input class="new-value" placeholder="Limitation Count Max" v-model="newLimitationCountMax" type="number" min="0" />
    <button class="action-button" :style="{ right: '50px', color: 'green' }" @click="addNewCompany();">
      <i :style="{ transform: 'scale(1.2)' }" class="bi bi-check-lg"></i>
    </button>
    <button class="action-button" :style="{
      right: '5px',
      color: 'red',
    }" @click="handleClose">
      <i :style="{ transform: 'scale(1.5)' }" class="bi bi-x"></i>
    </button>
  </div>
  <div class="container-companies">
    <trinity-rings-spinner v-if="spinnerStatus" class="spinner" :animation-duration="2000" :size="90" color="#000000" />
    <div class="label-row">
      <div class="label">Company Id</div>
      <div class="label">Company Name</div>
      <div class="label">Download Count</div>
      <div class="label">Download Limit</div>
      <div class="label">Download Ratio</div>
      <div class="label">Limitation Status</div>
      <div class="label">Limitation Count</div>
      <div class="label">Limitation Count Max</div>
    </div>
    <div v-for="(company, idx) in companyList" class="company-row" :key="idx" :style="{
      boxShadow: isEdited === idx ? '0 0 20px 2px lightgray' : 'none',
      filter: isEdited === idx || isEdited === null ? 'none' : 'blur(10px)',
      pointerEvents: isEdited === idx || isEdited === null ? 'auto' : 'none',
    }">
      <input class="value" :value="company.id" :disabled="true" type="number" />
      <input class="value" :value="company.company_name" :disabled="true" type="text" />

      <input ref="downloadCountInput" class="value" :value="company.download_count" :disabled="true" type="number" />

      <input ref="downloadLimitInput" class="value" v-model="company.download_limit" :disabled="isEdited !== idx"
        type="number" :style="{
          border: isEdited === idx ? '1px solid lightgray' : 'none'
        }" />

      <input class="value" :value="calculateUsage(company.download_count, company.download_limit)[0]" :disabled="true"
        type="text" :style="{ color: calculateUsage(company.download_count, company.download_limit)[1] }" />

      <input class="checkbox-value" v-model="company.limitation_status" :disabled="isEdited !== idx" type="checkbox"
        :style="{
          border: isEdited === idx ? '1px solid lightgray' : 'none'
        }" />

      <input class="value" v-model="company.limitation_count" :disabled="true" type="number" />

      <input class="value" v-model="company.limitation_count_max" :disabled="isEdited !== idx" type="number"
        :style="{ border: isEdited === idx ? '1px solid lightgray' : 'none' }" />

      <button class="action-button" :style="{ right: '70px', color: 'black' }" @click="
        handleEdit(idx); updateDownloadCount(idx); updateDownloadLimit(idx);">
        <i v-if="isEdited !== idx" class="bi bi-pen"></i>
        <i v-else :style="{ color: 'green', transform: 'scale(1.2)' }" class="bi bi-check-lg"
          @click="updateCompany(company.id)"></i>
      </button>
      <button class="action-button" :style="{ right: '40px', color: 'red' }">
        <i class="bi bi-trash3" @click="deleteCompany(company.id)"></i>
      </button>
      <button v-if="!(isEdited !== idx)" class="action-button" :style="{ right: '10px', color: 'black' }"
        @click="isEdited = null;">
        <i class="bi bi-x"></i>
      </button>
    </div>
  </div>
  <toast-view ref="toast"></toast-view>
</template>

<script>
import { mapActions } from "pinia";
import { useCompanyStore } from "@/store/modules/admin/company";
import { TrinityRingsSpinner } from "epic-spinners";
import ToastView from "@/components/admin/ToastView.vue";

export default {
  components: {
    TrinityRingsSpinner, ToastView
  },
  data() {
    return {
      isEdited: null,
      isClosed: false,
      companyList: [],
      newCompanyName: "",
      newDownloadCount: null,
      newDownloadLimit: null,
      newLimitationStatus: null,
      newLimitationCount: null,
      newLimitationCountMax: null,
      spinnerStatus: false,
      downloadCountValue: null,
    };
  },
  computed: {

  },
  methods: {
    ...mapActions(useCompanyStore, ["fetchCompanyDataRequest", "createCompanyRequest", "updateCompanyRequest", "deleteCompanyRequest"]),

    handleEdit(id) {
      if (this.isEdited !== null) {
        this.isEdited = null;
      } else {
        this.isEdited = id;
      }
    },
    handleClose() {
      if (this.isClosed) {
        this.isClosed = false;
      } else {
        this.isClosed = true;
      }
      this.newCompanyName = "";
      this.newDownloadCount = null;
      this.newDownloadLimit = null;
    },
    updateDownloadCount(id) {
      const downloadCount = this.$refs.downloadCountInput[id].value;
      if (downloadCount && this.isEdited === null) {
        this.companyList[id].download_count = downloadCount;
      }
    },
    updateDownloadLimit(id) {
      const downloadLimit = this.$refs.downloadLimitInput[id].value;
      if (downloadLimit && this.isEdited === null) {
        this.companyList[id].download_limit = downloadLimit;
      }
    },
    calculateUsage(downloadCount, downloadLimit) {
      const usage = Math.round((downloadCount * 100) / downloadLimit);
      let color = "green";
      if (usage > 50) {
        color = "orange";
      }
      if (usage >= 80) {
        color = "red";
      }
      return [`${usage}%`, color];
    },
    async getCompanies() {
      this.spinnerStatus = true;
      await this.fetchCompanyDataRequest({}).then((response) => {
        this.companyList = response.data.data.company_list;
        this.companyList = this.companyList.sort((a, b) =>
          a.company_name.localeCompare(b.company_name)
        )
        this.spinnerStatus = false;
      });
    },
    async addNewCompany() {
      this.spinnerStatus = true;
      if (this.newLimitationStatus === null) {
        this.newLimitationStatus = false;
        this.newLimitationCount = 0;
        this.newLimitationCountMax = 0;
      } else if (!this.newLimitationStatus) {
        this.newLimitationCount = 0;
        this.newLimitationCountMax = 0;
      }
      await this.createCompanyRequest({
        name: this.newCompanyName,
        logo: "",
        download_count: this.newDownloadCount,
        download_limit: this.newDownloadLimit,
        limitation_status: this.newLimitationStatus,
        limitation_count: this.newLimitationCount,
        limitation_count_max: this.newLimitationCountMax,
      }).then(() => {
        this.$refs.toast.triggerToast(`${this.newCompanyName} has been successfully created!`, "top-center", "success");
        this.getCompanies();
      }).catch((error) => {
        this.spinnerStatus = false;
        this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
      })
      this.handleClose();
    },
    async updateCompany(companyId) {
      this.spinnerStatus = true;
      let selectedCompany = this.companyList.filter(company => company.id === companyId)[0];
      let payload = {
        company_id: companyId,
        download_count: +selectedCompany.download_count,
        download_limit: +selectedCompany.download_limit,
        limitation_status: selectedCompany.limitation_status,
        limitation_count: +selectedCompany.limitation_count,
        limitation_count_max: +selectedCompany.limitation_count_max
      }
      await this.updateCompanyRequest(payload).then(() => {
        this.getCompanies();
        this.$refs.toast.triggerToast(`${selectedCompany.company_name} has been successfully updated!`, "top-center", "success");
      }).catch((error) => {
        this.spinnerStatus = true;
        this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
      })
    },
    async deleteCompany(companyId) {
      this.spinnerStatus = true;
      let companyName = this.companyList.filter(company => company.id === companyId)[0].company_name
      await this.deleteCompanyRequest(companyId).then(() => {
        this.getCompanies();
        this.spinnerStatus = false;
        this.$refs.toast.triggerToast(`${companyName} has been successfully deleted!`, "top-center", "success");
      }).catch((error) => {
        this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
      })
    }
  },
  mounted() {
    this.getCompanies();
  },
};
</script>

<style>
.container-companies {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 2px #ced4da;
  animation: SlideAnimation 0.8s ease;
  overflow: auto;
}

.label-row {
  width: 100%;
  height: 50px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  padding: 0 60px 0 30px;
  box-sizing: border-box;
}

.label {
  width: 10%;
  font-size: 12px;
  color: black;
  margin-right: 2%;
}

.company-row {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ced4da;
  display: flex;
  align-items: center;
  padding: 0 60px 0 30px;
  box-sizing: border-box;
  position: relative;
  transition: 0.2s all;
}

.value {
  border: none;
  width: 11% !important;
  font-size: 12px;
  height: 30px !important;
  outline: none !important;
  background-color: white;
  margin-right: 1% !important;
  padding: 5px !important;
}

.add-company-button {
  width: 150px;
  height: 40px;
  position: absolute;
  right: 40px;
  top: 60px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.add-company-button:hover {
  background-color: white;
  color: black;
  box-shadow: 0 0 10px 2px #ced4da;
}

.action-button {
  width: 25px;
  height: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
}

.action-button:hover {
  box-shadow: 0 0 10px 2px #ced4da;
}

.edit-box {
  width: 40%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% + 125px), -50%);
  border: 1px solid black;
  background-color: white;
  z-index: 1;
}

.add-company-container {
  width: 0;
  height: 60px;
  position: absolute;
  right: 40px;
  top: 60px;
  box-shadow: 0 0 10px 2px #ced4da;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 5px;
  transition: 0.2s all;
  overflow: hidden;
}

.new-value {
  width: 140px !important;
  height: 40px !important;
  padding: 5px !important;
  outline: none !important;
  border: 1px solid lightgray;
  font-size: 10px;
  margin: 0 5px 0 5px !important;
}

.checkbox-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px !important;
  height: 40px !important;
  padding: 5px !important;
  border: 1px solid lightgray;
  font-size: 10px;
  outline: none !important;
  margin: 0 5px 0 5px !important;
}

.spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.checkbox-value {
  height: 20px !important;
  width: 11% !important;
  margin-right: 1% !important;
  padding: 5px !important;
}
</style>