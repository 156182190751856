<template>
    <div class="container-register">
      <div class="input-container" >
        <trinity-rings-spinner v-if="spinnerStatus" class="spinner" :animation-duration="2000" :size="60" color="#000000" />
        <input v-if="!spinnerStatus" v-model="firstName" class="register-input" placeholder="First Name" type="text" />
        <input v-if="!spinnerStatus" v-model="lastName" class="register-input" placeholder="Last Name" type="text" />
        <input v-if="!spinnerStatus" v-model="username" class="register-input" placeholder="Username" type="text" />
        <input v-if="!spinnerStatus" v-model="password" class="register-input" placeholder="Password" type="password" />
        <input v-if="!spinnerStatus" v-model="email" class="register-input" placeholder="Email" type="text" />
        <input v-if="!spinnerStatus" v-model="companyId" class="register-input" placeholder="CompanyId" type="number" style="margin: 8px;" />
        <div v-if="!spinnerStatus" class="submit-button" @click="addNewUser">Register</div>
      </div>
      <toast-view ref="toast"></toast-view>
    </div>
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useUserStore } from "@/store/modules/admin/user"
  import { TrinityRingsSpinner } from "epic-spinners";
  import ToastView from "@/components/admin/ToastView.vue";
  
  export default {
    components: {
      TrinityRingsSpinner, ToastView
    },
    data() {
      return {
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        email: "",
        companyId: null,
        spinnerStatus: false,
      };
    },
    methods: {
      ...mapActions(useUserStore, ['registerUser']),
  
      async addNewUser() {
        this.spinnerStatus = true;
        const payload = {
          "first_name": this.firstName,
          "last_name": this.lastName,
          "username": this.username,
          "password": this.password,
          "email": this.email,
          "company_id": this.companyId
        }
        this.registerUser(payload).then(() => {
          this.spinnerStatus = false;
          this.$refs.toast.triggerToast(`${this.username} has been successfully registered!`, "top-center", "success");
        }).catch((error) => {
          this.spinnerStatus = false;
          this.$refs.toast.triggerToast(error.response.data.errors[0], "top-center", "error");
        })
      }
    },
  };
  </script>
  
  <style scoped>
  .container-register {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .input-container {
    width: 40%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 2px #ced4da;
    animation: SlideAnimation 0.8s ease;
    position: relative;
  }
  
  .spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .register-input {
    width: 60% !important;
    padding: 10px !important;
    outline: none;
    border: 0.5px solid #6c757d;
    font-size: 14px;
    transition: all 0.5s;
  }
  
  .register-input:focus {
    box-shadow: 0 0 10px 2px #ced4da;
  }
  
  .submit-button {
    width: 60%;
    height: auto;
    padding: 10px;
    margin: 20px;
    color: white;
    background-color: black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
  }
  
  .submit-button:hover {
    background-color: rgba(0, 0, 0, 0.746);
  }
  </style>